// src/components/Fighter/FighterSelector.tsx

import React, { useState, useEffect, useRef, useReducer, forwardRef } from 'react';
import { Fighter } from '../../store/types/fighterTypes';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import { eventCloud } from '../../eventCloud';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import { FighterLevelUp } from './FighterLevelUp';

import { ReactComponent as DownArrowIcon } from '../../assets/icons/svg/down-arrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as SponsorCrown } from '../../assets/icons/svg/sponsor-crown.svg';

import { ProgressBar } from '../ProgressBar/ProgressBar';

// Ensure Modal is attached to the app element for accessibility
Modal.setAppElement('#root');

// Define Action Types
type Action =
	| { type: 'UPDATE_USER_FIGHTERS'; payload: Fighter[] }
	| { type: 'ADD_FIGHTER'; payload: Fighter }
	| { type: 'DELETE_FIGHTER'; payload: string }; // payload is fighter.id

// Reducer Function to manage fighters state
const fightersReducer = (state: Fighter[], action: Action): Fighter[] => {
	switch (action.type) {
		case 'UPDATE_USER_FIGHTERS':
			return Array.isArray(action.payload) ? action.payload : [];
		case 'ADD_FIGHTER':
			return [...state, action.payload];
		case 'DELETE_FIGHTER':
			return state.filter((fighter) => fighter.id !== action.payload);
		default:
			return state;
	}
};

export const FighterSelector = forwardRef<HTMLDivElement>((_, ref) => {
	// Initialize useReducer with the fightersReducer and an empty array as the initial state
	const [fighters, dispatchFighters] = useReducer(fightersReducer, []);
	const fighter = useRef<Fighter | null>(null);
	const [selectedFighter, setSelectedFighter] = useState<Fighter | null>(null);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isLevelModalOpen, setIsLevelModalOpen] = useState<boolean>(false);
	const [newFighterName, setNewFighterName] = useState<string>('');
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [deleteCharacterModalOpen, setDeleteCharacterModalOpen] = useState(false);
	const [characterToDelete, setCharacterToDelete] = useState<Fighter | null>(null);
	const [selectedClass, setSelectedClass] = useState(0);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (isModalOpen) {
			setTimeout(() => {
				inputRef.current?.focus();
			}, 0); // Delay to ensure focus after rendering
		}
	}, [isModalOpen]);

	// Select a fighter
	const selectFighter = (newFighter: Fighter | null) => {
		console.log('selectFighter', newFighter);
		if (fighter.current?.id === newFighter?.id) return; // Prevent re-selecting the same fighter
		setSelectedFighter(newFighter);
		fighter.current = newFighter;
		setDropdownOpen(false);

		if (newFighter === null) {
			localStorage.removeItem('selectedFighter');
			eventCloud.fighterStore.next(null);
		} else {
			localStorage.setItem('selectedFighter', newFighter.id);

			if (newFighter.name != 'NEO') {
				eventCloud.sendAuthFighter(newFighter.id);
			}
		}

		eventCloud.emit('selected_fighter', newFighter);
	};

	useEffect(() => {
		const handleUserFightersUpdate = (fightersData: Fighter[] | null) => {
			console.log('userFightersStore', fightersData);
			if (Array.isArray(fightersData)) {
				dispatchFighters({
					type: 'UPDATE_USER_FIGHTERS',
					payload: fightersData,
				});

				const storedSelectedFighter = localStorage.getItem('selectedFighter');

				if (storedSelectedFighter) {
					try {
						const fighterExists = fightersData.find((f) => f.id === storedSelectedFighter);

						if (fighterExists) {
							selectFighter(fighterExists); // Ensure re-render by setting state directly
							fighter.current = fighterExists; // Update ref
						} else if (fightersData.length > 0 && fighter.current?.id !== fightersData[0].id) {
							selectFighter(fightersData[0]); // Ensure re-render by setting state directly
							fighter.current = fightersData[0]; // Update ref
						} else {
							selectFighter(null);
							fighter.current = null;
						}
					} catch {
						selectFighter(null);
						fighter.current = null;
					}
				} else if (fightersData.length > 0 && fighter.current?.id !== fightersData[0].id) {
					selectFighter(fightersData[0]); // Ensure re-render by setting state directly
					fighter.current = fightersData[0]; // Update ref
				}
			} else {
				dispatchFighters({
					type: 'UPDATE_USER_FIGHTERS',
					payload: [],
				});
				selectFighter(null);
				fighter.current = null;
				//setIsModalOpen(true);
			}
		};

		eventCloud.on('user_fighters_update', handleUserFightersUpdate);

		const handleFighterUpdate = (updatedFighter: Fighter | null) => {
			console.log('updatedFighter', updatedFighter);
			if (updatedFighter?.id == fighter.current?.id) {
				if (updatedFighter?.level && fighter.current?.level && updatedFighter?.level > fighter.current?.level) {
					//if (updatedFighter?.experience && fighter.current?.experience && updatedFighter?.experience > fighter.current?.experience) {
					setIsLevelModalOpen(true);
				}
				fighter.current = updatedFighter;
				setSelectedFighter(updatedFighter);
			}
		};

		eventCloud.on('fighter_update', handleFighterUpdate);

		const handleFighterSelect = (fighterToSelect: Fighter | null) => {
			console.log('handleFighterSelect', fighterToSelect);
			selectFighter(fighterToSelect);
		};

		eventCloud.on('select_fighter', handleFighterSelect);


		const handleCreateFighter = () => {
			setIsModalOpen(true);
		};

		eventCloud.on('create_fighter', handleCreateFighter);

		return () => {
			eventCloud.off('user_fighters_update', handleUserFightersUpdate);
			eventCloud.off('fighter_update', handleFighterUpdate);
			eventCloud.off('select_fighter', handleFighterSelect);
			eventCloud.off('create_fighter', handleCreateFighter);
		};
	}, []);

	// Handle clicks outside the dropdown to close it
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const handleFighterCreated = (fighter: Fighter) => {
			selectFighter(fighter);
		};

		eventCloud.on('fighter_created', handleFighterCreated);

		return () => {
			eventCloud.off('fighter_created', handleFighterCreated);
		};
	}, []);

	// Toggle dropdown visibility
	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	// Delete a fighter
	const deleteFighterHandler = (fighter: Fighter) => {
		setCharacterToDelete(fighter);
		setDeleteCharacterModalOpen(true);
	};

	const deleteFighter = (id: string | undefined) => {
		if (!id) return;
		setDeleteCharacterModalOpen(false);
		eventCloud.sendDeleteFighter(id);
	};

	// Open the modal to create a new fighter
	const openModal = () => {
		setIsModalOpen(true);
		setDropdownOpen(false);
	};

	// Close the modal
	const closeModal = () => {
		setIsModalOpen(false);
		setNewFighterName('');
	};

	const closeDeleteCharacterModal = () => {
		setDeleteCharacterModalOpen(false);
		setCharacterToDelete(null);
	};
	// Create a new fighter
	const createFighter = () => {
		console.log('createFighter', newFighterName);
		if (newFighterName.trim() === '') {
			toast.error('Fighter name cannot be empty.', {
				position: 'bottom-right',
				autoClose: 500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
			return;
		}

		setIsModalOpen(false);
		setNewFighterName('');

		// Optionally, send the create fighter request to the server
		eventCloud.sendCreateFighter(newFighterName.trim(), selectedClass);
	};

	return (
		<div className="w-full">
			<FighterLevelUp
				fighter={selectedFighter}
				isOpen={isLevelModalOpen}
				onClose={() => {
					setIsLevelModalOpen(false);
				}}
			/>
			<div className="w-full" ref={dropdownRef}>
				{/* Conditional Rendering of the Dropdown Title */}
				{fighters.length > 0 ? (
					<div className="flex flex-row space-x-2">
						<div className="w-full">
							<button
								onClick={toggleDropdown}
								className="relative active:top-px w-full inline-flex justify-between items-center w-1/2 rounded-sm px-2 pt-1 pb-1 bg-gray-550 text-sm font-bold text-white"
								id="fighter-menu"
								aria-haspopup="true"
								aria-expanded={dropdownOpen}
							>
								<div className="w-full">
									<div className="flex justify-between">
										<div className="pb-1 flex items-center gap-x-1">
											{selectedFighter?.name}
											<span className="text-blue-550">[{selectedFighter?.level}]</span>
											{selectedFighter?.sponsor_expires &&
												new Date(selectedFighter.sponsor_expires) > new Date() && (
													<SponsorCrown width={13} height={13} />
												)}
										</div>
									</div>
									<div>
										<ProgressBar
											progress={
												(Number(selectedFighter?.experience) /
													Number(selectedFighter?.exp_for_next_level)) *
												100
											}
											height="h-1"
										/>
									</div>
								</div>
								<div>
									<DownArrowIcon
										className={classNames('ml-2', {
											'rotate-180': dropdownOpen,
										})}
										width={8}
										height={8}
									/>
								</div>
							</button>
						</div>
					</div>
				) : (
					<> </>
					// <div className="w-full">
					// 	<Button className="p-2" variant="green" onClick={openModal}>
					// 		CREATE NEW CHARACTER
					// 	</Button>
					// </div>
				)}

				{/* Dropdown Panel */}
				{dropdownOpen && fighters.length > 0 && (
					<div
						className="flex flex-col max-h-full origin-top-right sm:mr-2 p-4 absolute right-0 sm:mt-2 w-80 rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 z-10 bg-black text-white border border-gray-550 fixed sm:flex top-0 bottom-0 w-full sm:w-auto"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="options-menu"
					>
						<div className="text-m flex justify-between">
							<div>Select Character</div>
							<div>
								<button onClick={() => setDropdownOpen(false)}>
									<CloseIcon width={14} height={14} />
								</button>
							</div>
						</div>
						<div className="py-4 flex-grow overflow-y-auto" role="none">
							<div className="">
								{fighters.map((fighter, index) => (
									<div
										key={index}
										className={classNames(
											'relative active:top-px p-2 mb-2 rounded-sm cursor-pointer select-none',
											{
												'bg-gray-550': selectedFighter?.id != fighter.id,
											},
											{
												'bg-blue-550 bg-opacity-20 ring-1 ring-inset ring-blue-550':
													selectedFighter?.id == fighter.id,
											}
										)}
									>
										<div
											onClick={() => {
												selectFighter(fighter);
												setDropdownOpen(false);
											}}
											className="flex"
										>
											<div className="flex-grow">
												<div className="flex flex-grow items-center">
													<div
														className="text-sm cursor-pointer rounded p-2 font-regular text-base flex-grow flex items-center"
														role="menuitem"
													>
														<div className="flex justify-between items-center text-base font-bold gap-x-1">
															<span>{fighter.name}</span>
															<span className="text-blue-550">[{fighter.level}]</span>
															{fighter?.sponsor_expires &&
																new Date(fighter.sponsor_expires) > new Date() && (
																	<SponsorCrown width={14} height={14} />
																)}
														</div>
													</div>
													<div className="flex space-x-2">
														{selectedFighter?.id == fighter.id && (
															<div className="bg-blue-550 rounded-sm text-black text-xs py-0.5 px-1 font-bold">
																Current
															</div>
														)}
														<div className="flex space-x-2">
															<Button
																size="small"
																variant="red-trans"
																onClick={() => {
																	deleteFighterHandler(fighter);
																}}
															>
																Delete
															</Button>
														</div>
													</div>
												</div>
												<div className="ml-2 mb-2">
													<div className="flex justify-between font-regular text-xxs mb-1">
														<span>
															{fighter.experience}
															xp
														</span>
														<span className="text-gray-200">
															{fighter.exp_for_next_level}
															xp
														</span>
													</div>
													<ProgressBar
														progress={
															(Number(fighter.experience) /
																Number(fighter.exp_for_next_level)) *
															100
														}
													/>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="space-y-2">
							<Button size="large" onClick={openModal}>
								CREATE NEW CHARACTER
							</Button>
						</div>
					</div>
				)}
			</div>

			{/* Modal for Creating a New Fighter */}
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => {
					setIsModalOpen(false);
				}}
				contentLabel="Create New Fighter"
				className="sm:w-[400px] max-w-md mx-1 xs:mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 fixed bottom-0 w-full sm:block sm:w-auto sm:bottom-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-8">
					<div className="flex-grow">
						<span className="font-regular text-m mb-4">Create New Character</span>
					</div>
					<div
						onClick={() => {
							setIsModalOpen(false);
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div className="flex flex-col gap-y-2">
					<div className="flex w-full gap-x-2">
						<div
							className="flex flex-col gap-y-2 w-1/2 cursor-pointer"
							onClick={() => {
								setSelectedClass(0);
							}}
						>
							<div
								className={classNames(
									`font-bold text-center ${selectedClass == 0 ? 'text-blue-550' : 'text-gray-200'}`
								)}
							>
								Knight
							</div>
							<div
								className={classNames(
									`border rounded-sm p-4 ${selectedClass == 0 ? 'border-blue-550' : 'border-gray-550'}`
								)}
							>
								<img src="/assets/AVATAR_0.png" />
							</div>
						</div>

						<div
							className="flex flex-col gap-y-2 w-1/2 cursor-pointer"
							onClick={() => {
								setSelectedClass(1);
							}}
						>
							<div
								className={classNames(
									`font-bold text-center ${selectedClass == 1 ? 'text-blue-550' : 'text-gray-200'}`
								)}
							>
								Wizard
							</div>
							<div
								className={classNames(
									`border rounded-sm p-4 ${selectedClass == 1 ? 'border-blue-550' : 'border-gray-550'}`
								)}
							>
								<img src="/assets/AVATAR_1.png" />
							</div>
						</div>
					</div>
					<div className="text-bold text-xxs text-gray-200 mt-2">NAME</div>
					<input
						ref={inputRef}
						type="text"
						value={newFighterName}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {								
								createFighter();
							}
						}}
						onChange={(e) => setNewFighterName(e.target.value)}
						placeholder="Enter character name"
						className="w-full px-3 py-2 rounded-sm text-base placeholder-gray-200 bg-gray-550 font-regular outline-none"
					/>
					<div className="flex justify-end space-x-2 mt-4">
						<Button
							variant="black"
							size="large"
							onClick={() => {
								createFighter();
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>

			{/* Modal for deleting character */}
			<Modal
				isOpen={deleteCharacterModalOpen}
				onRequestClose={() => {
					setDeleteCharacterModalOpen(false);
					setCharacterToDelete(null);
				}}
				contentLabel="Create New Fighter"
				className="w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 fixed bottom-0 w-full sm:w-auto sm:bottom-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-8">
					<div className="flex-grow">
						<span className="font-regular text-m mb-4">Delete Character</span>
					</div>
					<div
						onClick={() => {
							setDeleteCharacterModalOpen(false);
							setCharacterToDelete(null);
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div>
					<div className="text-sm text-gray-200 mb-2">
						Are you sure you want to delete{' '}
						<span className="font-bold text-white">{characterToDelete?.name}</span>{' '}
						<span className="font-bold text-blue-550">[{characterToDelete?.level}]</span>
					</div>
					<div className="flex justify-end space-x-2 mt-8">
						<Button
							variant="black"
							size="large"
							onClick={() => {
								deleteFighter(characterToDelete?.id);
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
});

FighterSelector.displayName = 'FighterSelector';
