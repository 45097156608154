import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as XLogo } from '../../assets/icons/svg/x.svg';
import { ReactComponent as TelegramLogo } from '../../assets/icons/svg/telegram.svg';
import { ReactComponent as IconLogo } from '../../assets/icons/svg/logo.svg';
import { ReactComponent as IconClose } from '../../assets/icons/svg/close.svg';
import { ReactComponent as DonateIcon } from '../../assets/icons/svg/donate.svg';

import { Wallets } from '../Wallets/Wallets';

const MenuMobile: React.FC<{ onClose: any, setFighter: any }> = ({ onClose, setFighter }) => {
	return (
		<div className="fixed inset-0 bg-black text-white flex flex-col justify-between items-center  font-bold">
			<div className="flex justify-between w-full px-2 pt-3.5 gap-x-2">
				<IconLogo width={80} />
				<div className="flex gap-x-2 items-center">
					<Wallets setFighter={setFighter} />
					<div className="bg-gray-550 p-2.5">
						<IconClose onClick={onClose} width={20} />
					</div>
				</div>
			</div>
			<div className="text-center gap-y-1 flex flex-col">
				<NavLink to="/profile" onClick={onClose} className="relative active:top-px bg-gray-550 py-2 px-8">
					PROFILE
				</NavLink>
				<NavLink to="/shop" onClick={onClose} className="relative active:top-px bg-gray-550 py-2 px-8">
					SHOP
				</NavLink>
				<NavLink to="/market" onClick={onClose} className="relative active:top-px bg-gray-550 py-2 px-8">
					MARKETPLACE
				</NavLink>

				{/*<NavLink to="/leaderboard" onClick={onClose}>
					LEADERBOARD
				</NavLink>*/}

				<NavLink
					to="/sponsor"
					onClick={onClose}
					className="relative active:top-px bg-gray-550 py-2 px-8 flex items-center text-yellow-500 justify-center"
				>
					<DonateIcon className="mr-0.5" /> VIP
				</NavLink>
				<NavLink to="/aff" onClick={onClose} className="relative active:top-px bg-gray-550 py-2 px-8">
					REFERR A FRIEND
				</NavLink>
				<NavLink
					to="https://docs.combats.game"
					target="_blank"
					className="relative active:top-px bg-gray-550 py-2 px-8"
				>
					DOCS
				</NavLink>
			</div>
			<div className="flex justify-between w-full p-2 gap-x-1">
				<div className="w-1/2 bg-gray-550 justify-center flex">
					<a target="_blank" rel="noreferrer" href="https://x.com/CombatsGame">
						<XLogo height={40} />
					</a>
				</div>
				<div className="w-1/2 bg-gray-550 justify-center flex">
					<a target="_blank" rel="noreferrer" href="https://t.me/combats_game">
						<TelegramLogo height={40} />
					</a>
				</div>
			</div>
		</div>
	);
};

export default MenuMobile;
