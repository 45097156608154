// src/pages/ProfilePage.tsx

import React from 'react';
import ProfilePageNarrow from './ProfilePageNarrow';
import ProfilePageWide from './ProfilePageWide';
import { Fighter, Account, Wallet } from '../store/types/fighterTypes';

type ProfilePageProps = {
	fighter: Fighter | null;
	account: Account | null;
	wallet: Wallet | null;
};

const ProfilePage: React.FC<ProfilePageProps> = ({ fighter, account, wallet }) => {
	return (
		<div className="flex flex-col flex-grow h-full">
			<div className="block sm:hidden h-full flex-grow flex flex-col">
				<ProfilePageNarrow fighter={fighter} account={account} wallet={wallet} />
			</div>
			<div className="hidden sm:block flex-grow flex flex-col">
				<ProfilePageWide fighter={fighter} account={account} wallet={wallet} />
			</div>
		</div>
	);
};

export default ProfilePage;
