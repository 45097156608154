import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import { Combat, Fighter, Item } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import ClaimItem from '../Combat/ClaimItem';
import { ProgressBar } from '../ProgressBar/ProgressBar';

import { RoundTargetHits } from '../Combat/RoundTargetHits';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { ItemPopover } from '../Item/ItemPopover';

import Confetti from 'react-dom-confetti';

type CombatWinnerProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	combat: Combat;
};

export const CombatWinner = forwardRef<HTMLButtonElement, CombatWinnerProps>(({ combat, ...rest }, ref) => {
	const [fireConfetti, setFireConfetti] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Item | null>(null);

	const config = {
		angle: 74,
		spread: 500,
		startVelocity: 50,
		elementCount: 500,
		dragFriction: 0.13,
		duration: 5000,
		stagger: 1,
		width: '9px',
		height: '12px',
		perspective: 756,
		colors: [
			'#11DEEA',
			'#497FF2',
			'#9E68D5',
			'#94A2EE',
			'#3CCC90',
			'#8B8B90',
			'#FF6058',
			'#CE6DA8',
			'#FF8058',
			'#F0A64A',
			'#F0D352',
			'#B3AA8B',
		],
	};

	const navigate = useNavigate();
	// const handleCollectItem = (liquidate: boolean) => {
	// 	if (!combat) {
	// 		console.error("Combat is undefined");
	// 		return;
	// 	}

	// 	// Send the attack action to the backend using the sendAttack method
	// 	eventCloud.collectItem(combat.id, liquidate);
	// };

	const handleReturnToLobby = () => {
		navigate('/profile'); // Navigate to /lobby when the button is clicked
	};

	const handleWithdrawReward = () => {
		eventCloud.emit('open_credits');
	};

	const getWager = (): number => {
		return Math.min(combat.maker_wager, combat.taker_wager);
	};

	setTimeout(() => {
		setFireConfetti(true);
	}, 25);

	return (
		<div className="flex flex-col gap-y-1">
			{combat.winner?.id == eventCloud.selectedFighter?.id && getWager() > 0 && (
				<div
					className="w-full h-full gap-y-1 p-2 bg-cover bg-center border-gray-550 lg:p-4"
					style={{
						backgroundImage:
							combat.winner?.id === eventCloud.selectedFighter?.id
								? "url('/assets/--combat-winner-bg.png')"
								: "url('/assets/--combat-draw-bg.png')",
					}}
				>
					<div className="text-center text-yellow-500">You won the bet!</div>
					<div className="text-center font-bold text-green-500 text-2xl">+{getWager() * 0.9} CR</div>
					<div className="text-center flex flex-col items-center">
						<div
							style={{
								position: 'absolute', // Make the confetti container absolute
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								maxHeight: '100%', // Ensure it doesn't increase the height
								overflow: 'hidden', // Prevent overflow
								zIndex: 10,
								pointerEvents: 'none', // Disable interaction with the confetti
							}}
						>
							<div
								className="w-full h-full flex justify-center items-center"
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, calc(-50% - 200px))', // Center and move 100px higher
								}}
							>
								{/* Center the confetti horizontally and vertically */}
								<Confetti active={fireConfetti} config={config} />
							</div>
						</div>
					</div>
				</div>
			)}

			{combat.winner?.id != eventCloud.selectedFighter?.id && getWager() > 0 && (
				<div
					className="w-full h-full gap-y-1 p-2 bg-cover bg-center border-gray-550 lg:p-4"
					style={{
						backgroundImage:
							combat.winner?.id === eventCloud.selectedFighter?.id
								? "url('/assets/--combat-winner-bg.png')"
								: "url('/assets/--combat-draw-bg.png')",
					}}
				>
					<div className="text-center text-white">You lost the bet...</div>
					<div className="text-center font-bold text-red-550 text-2xl">-{getWager()} CR</div>
				</div>
			)}
			<div
				className="w-full h-full gap-y-1 p-2 bg-cover bg-center border-gray-550 rounded-sm lg:p-4"
				style={{
					backgroundImage:
						combat.winner?.id === eventCloud.selectedFighter?.id
							? "url('/assets/--combat-winner-bg.png')"
							: "url('/assets/--combat-draw-bg.png')",
				}}
			>
				<div className="flex flex-col justify-between">
					<div className="items-center justify-center">
						<div className="text-xxs lg:text-base text-center">Battle Finished!</div>
					</div>
					<div className="flex flex-col flex-grow">
						{combat.winner?.id == eventCloud.selectedFighter?.id ? (
							<div className="flex flex-row flex-col flex-grow justify-center">
								<div className="text-xs lg:text-2xl text-blue-550 font-bold mt-4 text-center">
									YOU WON!
								</div>

								<div className="text-center flex flex-col items-center">
									<div
										style={{
											position: 'absolute', // Make the confetti container absolute
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											maxHeight: '100%', // Ensure it doesn't increase the height
											overflow: 'hidden', // Prevent overflow
											zIndex: 10,
											pointerEvents: 'none', // Disable interaction with the confetti
										}}
									>
										<div
											className="w-full h-full flex justify-center items-center"
											style={{
												position: 'absolute',
												top: '50%',
												left: '50%',
												transform: 'translate(-50%, calc(-50% - 200px))', // Center and move 100px higher
											}}
										>
											{/* Center the confetti horizontally and vertically */}
											<Confetti active={fireConfetti} config={config} />
										</div>
									</div>
								</div>

								{combat.dropped_item != null ? (
									<div className="flex flex-col justify-between">
										<div className="text-center max-w-[20%] md:max-w-[30%] mx-auto mt-2">
											<ItemThumbnail
												item={combat?.dropped_item}
												onClick={(e: any) => {
													setSelectedItem(combat?.dropped_item);
													setAnchorEl(e.currentTarget);
												}}
											/>
										</div>
										<div className="mt-2 md:mt-4 text-white font-bold text-xxs md:text-m text-center">
											{combat.dropped_item.name}
										</div>
										{!combat.dropped_item_collected && !combat.dropped_item_liquidated && (
											<div className="w-full flex justify-center">
												<div className="mt-5 mb-5">
													<div>
														<Button
															size="large"
															variant="basic"
															// onClick={() => {
															// 	handleCollectItem(
															// 		false,
															// 	);
															// }}
														>
															CLAIM ITEM
														</Button>
													</div>
													{/*<div className="text-center my-4">
																OR
															</div>
															<div>
																<Button
																	size="large"
																	variant="red"
																	onClick={() => {
																		handleCollectItem(
																			true,
																		);
																	}}
																>
																	LIQUIDATE FOR{" "}
																	{
																		combat.dropped_item
																			.price
																	}{" "}
																	CR
																</Button>
															</div>*/}
												</div>
											</div>
										)}
									</div>
								) : (
									<div className="text-center text-2xl lg:text-4xl text-blue-550 font-bold">
										+{combat.dropped_credits} CR
									</div>
								)}
							</div>
						) : (
							<div className="flex-grow flex flex-col justify-center mt-4 items-center">
								<div className="text-center text-xs md:text-2xl">Winner</div>
								<div className="text-center text-2xl md:text-4xlfont-bold">{combat.winner?.name}</div>
							</div>
						)}

						{(combat.maker_id == eventCloud.selectedFighter?.id ||
							combat.taker_id == eventCloud.selectedFighter?.id) && (
							<div className="flex flex-col space-y-2 text-xxs">
								<div className="flex justify-between items-center">
									<div className="text-center">
										+
										{combat.maker_id == eventCloud.selectedFighter?.id
											? combat.maker_experience
											: combat.taker_experience}
										xp
									</div>
									<div className="text-green-500">
										{(
											(Number(eventCloud.selectedFighter?.experience) /
												Number(eventCloud.selectedFighter?.exp_for_next_level)) *
											100
										).toFixed(0)}
										% of Lvl {eventCloud.selectedFighter?.level + 1}
									</div>
								</div>
								<div>
									<ProgressBar
										progress={
											(Number(eventCloud.selectedFighter?.experience) /
												Number(eventCloud.selectedFighter?.exp_for_next_level)) *
											100
										}
										height="h-1"
									/>
								</div>
							</div>
						)}

						<div className="">
							{/*{combat.winner?.id == combat.maker_id &&
								combat.maker.victories == 1 &&
								combat.maker.defeats == 0 &&
								combat.maker.abandons == 0 && (
									<Button
										size="large"
										variant="green"
										onClick={ha ndleWithdrawReward}
										className="mt-4 lg:px-4 py-4 lg:text-2xl"
									>
										WITHDRAW REWARD
									</Button>
								)}*/}
							<Button
								size="large"
								variant="black"
								onClick={handleReturnToLobby}
								className="mt-4 lg:px-4 py-4 lg:text-2xl"
							>
								RETURN HOME
							</Button>
						</div>
					</div>
				</div>

				<ItemPopover
					selectedItem={selectedItem}
					anchorEl={anchorEl}
					fighter={combat.winner}
					onClose={() => {
						setSelectedItem(null);
						setAnchorEl(null);
					}}
				/>
			</div>
		</div>
	);
});

CombatWinner.displayName = 'CombatWinner';
