import classNames from 'classnames';
import React, { forwardRef, useState, useEffect } from 'react';
import { Combat, Fighter } from '../../store/types/fighterTypes';

import { Button } from '../Button/Button';
import { ReactComponent as HealthIcon } from '../../assets/icons/svg/health.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';

import { ReactComponent as CheckedIcon } from '../../assets/icons/svg/checked.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/icons/svg/unchecked.svg';
import { Tooltip } from 'react-tooltip';

import { eventCloud } from '../../eventCloud';

import { TargetHits } from '../Combat/TargetHits';

import { ReactComponent as HeadIcon } from '../../assets/icons/svg/head.svg';
import { ReactComponent as ChestIcon } from '../../assets/icons/svg/chest.svg';
import { ReactComponent as StomachIcon } from '../../assets/icons/svg/stomach.svg';
import { ReactComponent as CrutchIcon } from '../../assets/icons/svg/crutch.svg';
import { ReactComponent as LegsIcon } from '../../assets/icons/svg/legs.svg';
import { ReactComponent as FightIcon } from '../../assets/icons/svg/fight.svg';
import { ReactComponent as SkillIcon } from '../../assets/icons/svg/skill.svg';

import { ReactComponent as RefreshIcon } from '../../assets/icons/svg/refresh.svg';

type CombatAttackSelectorProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	combat: Combat;
	selectedSkill: string;
	setSelectedSkill: any;
	onSendAttack: any;
	autoHit: boolean;
	toggleAutoHit: any;
};

export const CombatAttackSelector = forwardRef<HTMLButtonElement, CombatAttackSelectorProps>(
	({ combat, selectedSkill, setSelectedSkill, onSendAttack, autoHit, toggleAutoHit, ...rest }, ref) => {
		// Lists of attack and defence options

		const [timeLeft, setTimeLeft] = useState(10);

		useEffect(() => {
			if (combat == null) return;
			if (combat.active_round == null) return;
			const interval = setInterval(() => {
				const currentTime = Date.now();
				const startedAtTimestamp = new Date(combat.active_round.started_at).getTime(); // Convert to timestamp
				const elapsedSeconds = Math.floor((currentTime - startedAtTimestamp) / 1000);
				const remainingSeconds = Math.max(10 - elapsedSeconds, 0);

				//console.log("timeLeft", currentTime, elapsedSeconds, remainingSeconds, startedAtTimestamp);
				setTimeLeft(remainingSeconds);
			}, 1000);

			return () => clearInterval(interval);
		}, [combat?.active_round]);

		useEffect(() => {
			if (combat == null) return;
			if (combat.active_round == null) return;
			const currentTime = Date.now();
			const startedAtTimestamp = new Date(combat.active_round.started_at).getTime(); // Convert to timestamp
			const elapsedSeconds = Math.floor((currentTime - startedAtTimestamp) / 1000);
			const remainingSeconds = Math.max(10 - elapsedSeconds, 0);

			//console.log("timeLeft", currentTime, elapsedSeconds, remainingSeconds, startedAtTimestamp);
			setTimeLeft(remainingSeconds);
		}, [combat]);

		const isAttackSubmitted = (): boolean => {
			if (!combat || !combat.active_round) return false;
			//console.log("[isAttackSubmitted]",eventCloud.selectedFighter, combat.taker.id, combat.active_round.taker_submitted )

			if (combat.maker_id == eventCloud.selectedFighter?.id && combat.active_round.maker_submitted) {
				//console.log("[isAttackSubmitted] true")
				return true;
			}

			if (combat.taker_id == eventCloud.selectedFighter?.id && combat.active_round.taker_submitted) {
				//console.log("[isAttackSubmitted] true")
				return true;
			}

			return false;
		};

		const isSpectatorInFight = (): boolean => {
			if (
				combat.maker_id == eventCloud.selectedFighter?.id ||
				combat.taker_id == eventCloud.selectedFighter?.id
			) {
				return true;
			}

			return false;
		};

		const getFighter = (): Fighter | undefined => {
			if (!eventCloud.selectedFighter) {
				return undefined;
			} else return eventCloud.selectedFighter;
		};

		return (
			<div className="flex flex-col space-y-2 w-full bg-gray-100">
				<div
					className="relative w-full h-full p-2 lg:p-4 bg-gray-100 border border-gray-550 rounded-sm flex flex-col select-none"
					// style={{
					// 	backgroundImage: "url('/assets/--combat-bg.png')",
					// }}
				>
					{isAttackSubmitted() && (
						<div className="absolute inset-0 bg-gray-550 bg-opacity-75 z-10 flex flex-col items-center justify-center text-center">
							<div className="text-xs lg:text-xl xl:text-2xl text-green-500 font bold">
								MOVE SUBMITTED!
							</div>
							<div className="text-xs lg:text-xl mt-4">AWAITING OPPONENT</div>
							<div>{timeLeft}s</div>
						</div>
					)}

					{/*<div className="w-full max-w-full">
						<div
							className="flex justify-center items-center gap-x-2 cursor-pointer  select-none"
							onClick={toggleAutoHit}
						>
							<div>{autoHit ? <CheckedIcon /> : <UncheckedIcon />}</div>
							<div>Auto Hit</div>
						</div>
						
					</div>*/}

					<div className="flex flex-wrap bs:flex-row gap-y-1 bs:gap-x-2 flex-grow">
						<div className="flex h-[40px] mt-2 w-full justify-center ">
							<div
								className={classNames(
									`cursor-pointer  rounded-sm relative active:top-px mr-2 text-red-550 ${selectedSkill == 'Basic' ? 'border-red-550' : 'border-gray-550'} border h-full aspect-[1/1] flex items-center justify-center bg-black`
								)}
								onClick={() => {
									setSelectedSkill('Basic');
								}}
							>
								<FightIcon className="w-2/3 h-2/3" />
							</div>
							<div className="flex gap-x-1 border-l border-gray-550 pl-2">
								{Array.from({ length: 3 }).map((_, index) => (
									<div key={index} className="h-full aspect-[1/1]">
										{(() => {
											const fighter = getFighter();

											return fighter && fighter.skills && fighter.skills.length > index ? (
												<div
													className={classNames(
														`w-[40px] h-[40px] relative rounded-sm ${selectedSkill == fighter.skills[index].name ? 'border-blue-550' : 'border-gray-550'} border cursor-pointer active:top-px`
													)}
													onClick={() => {
														console.log(
															'set skill ',
															fighter.skills[index],
															fighter?.intelligence
														);
														if (
															fighter.skills[index].required_int <= fighter?.intelligence
														) {
															console.log('set skill 2', fighter.skills[index]);
															setSelectedSkill(fighter.skills[index].name);
														}
													}}
												>
													<img
														src={`/assets/${fighter.skills[index].name || ''}.jpg`}
														data-tooltip-id={fighter.skills[index].name || ''}
													/>
													<Tooltip
														id={fighter.skills[index].name}
														className="z-10"
														style={{ fontSize: '10px' }}
														place="top"
													>
														<div className="text-center">
															<div>{fighter.skills[index].name}</div>
															<div>
																Damage:{' '}
																{(
																	((fighter.min_wiz_damage +
																		fighter.skills[index].min_wiz_damage) *
																		(100 + fighter.wiz_damage)) /
																	100
																).toFixed(0)}
																-
																{(
																	((fighter.max_wiz_damage +
																		fighter.skills[index].max_wiz_damage) *
																		(100 + fighter.wiz_damage)) /
																	100
																).toFixed(0)}
															</div>
															<div>Mana: {fighter.skills[index].mana_cost}</div>
															{fighter.skills[index].required_int > 0 && (
																<div
																	className={classNames(
																		`${fighter.skills[index].required_int > fighter?.intelligence ? 'text-red-550' : 'text-white'}`
																	)}
																>
																	Required Intelligence:{' '}
																	{fighter.skills[index].required_int}
																</div>
															)}
														</div>
													</Tooltip>
												</div>
											) : (
												<div className="text-gray-550 bg-black border-gray-550 border w-full h-full flex items-center justify-center">
													<SkillIcon className="w-2/3 h-2/3" />
												</div>
											);
										})()}
									</div>
								))}
							</div>
						</div>
					</div>

					{/*{(combat.maker_id == eventCloud.selectedFighter?.id &&
					combat.active_round?.taker_submitted) ||
				(combat.taker_id == eventCloud.selectedFighter?.id &&
					combat.active_round?.maker_submitted) ? (
					<div
						className="mt-4 h-[22px] text-center flex justify-center items-center bg-cover bg-center"
						style={{
							backgroundImage:
								"url('/assets/--combat-opponent-submitted.png')",
						}}
					>
						<div className="text-xs text-red-550 select-none">
							OPPONENT MOVE SUBMITTED
						</div>
					</div>
				) : (
					<div className="mt-4 h-[22px] text-center flex justify-center items-center bg-cover bg-center">
						<div className="text-sm text-red-550">&nbsp;</div>
					</div>
				)}*/}

					<div className="w-full max-w-full mt-4 flex flex-col gap-y-1">
						<div
							className="flex bg-gray-550 text-xxs lg:text-xs rounded-sm px-1 py-1 justify-between items-center gap-x-2 cursor-pointer select-none"
							onClick={toggleAutoHit}
						>
							<div className="flex gap-x-1 items-center">
								<RefreshIcon />
								Auto-Play
							</div>
							<div className="flex gap-x-1 items-center font-bold">
								{autoHit ? 'On' : 'Off'}
								{autoHit ? <CheckedIcon /> : <UncheckedIcon />}
							</div>
						</div>
						<Button
							size="large"
							onClick={onSendAttack}
							disabled={isAttackSubmitted()}
							className={` px-1 lg:px-4 py-4 text-xs lg:text-sm xl:text-xl w-full ${
								timeLeft < 5 ? 'bg-red-550' : 'bg-blue-550'
							}`}
						>
							SEND ATTACK
							<span
								className="ml-1"
								style={{
									display: 'inline-block',
									width: '40px',
									textAlign: 'right',
								}}
							>
								({timeLeft}s)
							</span>
						</Button>
					</div>

					<div className="flex justify-center space-x-2 mt-2  text-xxxs lg:text-xs">
						<div>Your Damage so far:</div>
						<div className="text-red-550 font-bold flex items-center space-x-1">
							<HealthIcon width={12} height={12} />
							<div>
								{combat.maker_id == eventCloud.selectedFighter?.id && combat.maker_total_damage}
								{combat.taker_id == eventCloud.selectedFighter?.id && combat.taker_total_damage}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

CombatAttackSelector.displayName = 'CombatAttackSelector';
