import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { eventCloud } from './eventCloud';
import { Account, Wallet, Fighter } from './store/types/fighterTypes';
import HomePage from './pages/HomePage';
import LobbyPage from './pages/LobbyPage';
import CombatPage from './pages/CombatPage';
import ProfilePage from './pages/ProfilePage';
import InventoryPage from './pages/InventoryPage';
import MarketPage from './pages/MarketPage';
import HistoryPage from './pages/HistoryPage';
import LeaderboardPage from './pages/LeaderboardPage';
import CharactersPage from './pages/CharactersPage';
import ShopPage from './pages/ShopPage';
import AffiliatePage from './pages/AffiliatePage';
import LandingPage from './pages/LandingPage';
import SponsorPage from './pages/SponsorPage';
import NotFoundPage from './pages/NotFoundPage';
import Header from './components/Shared/Header';
import MenuMobile from './components/Shared/MenuMobile';
import HomeHeader from './components/Shared/HomeHeader';

import 'react-toastify/dist/ReactToastify.css';
import './css/App.css';
import LoadingSpinner from './components/Shared/LoadingSpinner';
import EventListener from './components/EventListener';

import './css/font.css';

const App: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [isHomePage, setIsHomePage] = useState<boolean>(location.pathname === '/');

	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [connectionError, setConnectionError] = useState<string | null>(null);
	const [fighter, setFighter] = useState<Fighter | null>(null);
	const [account, setAccount] = useState<Account | null>(null);
	const [wallet, setWallet] = useState<Wallet | null>(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		// Check for the referrer link in URL parameters
		const queryParams = new URLSearchParams(location.search);
		const referrer = queryParams.get('r');

		if (referrer) {
			localStorage.setItem('cg_afl', referrer);

			// Remove the referrer from the URL
			queryParams.delete('r');
			const newUrl = `${location.pathname}?${queryParams.toString()}`;
			console.warn('newUrl', newUrl);
			navigate(newUrl, { replace: true });
		}
	}, [location, navigate]);

	useEffect(() => {
		setIsHomePage(location.pathname === '/');
	}, [location]);

	useEffect(() => {
		if (isHomePage) return;
		const handleWalletSelected = (selectedWallet: Wallet) => {
			setWallet(selectedWallet);
		};
		const handleAccountUpdate = (updatedAccount: Account) => {
			setAccount(updatedAccount);
		};
		const handleFighterUpdate = (updatedFighter: Fighter) => {
			if (updatedFighter.id === fighter?.id) setFighter({ ...updatedFighter });
		};
		const handleFighterSelected = (selectedFighter: Fighter) => {
			setFighter(selectedFighter);
		};

		eventCloud.on('wallet_selected', handleWalletSelected);
		eventCloud.on('account_update', handleAccountUpdate);
		eventCloud.on('fighter_update', handleFighterUpdate);
		eventCloud.on('selected_fighter', handleFighterSelected);

		return () => {
			eventCloud.off('wallet_selected', handleWalletSelected);
			eventCloud.off('account_update', handleAccountUpdate);
			eventCloud.off('fighter_update', handleFighterUpdate);
			eventCloud.off('selected_fighter', handleFighterSelected);
		};
	}, [fighter, isHomePage]);

	useEffect(() => {
		const handleOpen = () => {
			setIsConnected(true);
			setConnectionError(null);
		};
		const handleClose = (payload: any) => {
			setIsConnected(false);
			setConnectionError(payload.reason || 'Connection closed');
		};
		const handleError = (error: any) => {
			setConnectionError('WebSocket encountered an error');
		};

		eventCloud.on('open', handleOpen);
		eventCloud.on('close', handleClose);
		eventCloud.on('error', handleError);

		return () => {
			eventCloud.off('open', handleOpen);
			eventCloud.off('close', handleClose);
			eventCloud.off('error', handleError);
		};
	}, [isHomePage]);

	if (connectionError || (!isConnected && !isHomePage)) {
		return (
			<div className="h-full w-full">
				<LoadingSpinner />
				<ToastContainer position="bottom-right" theme="dark" hideProgressBar autoClose={1000} />
			</div>
		);
	}

	return (
		<div className="app-container overflow-x-hidden p-2 min-h-[100svh] h-full">
			{!isHomePage ? (
				<Header account={account} fighter={fighter} wallet={wallet} setFighter={setFighter} setIsMenuOpen={setIsMenuOpen} />
			) : (
				<HomeHeader />
			)}
			<div className="router-container py-2 max-w-[2000px] w-full mx-auto">
				<EventListener />
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route
						path="/profile"
						element={<ProfilePage account={account} fighter={fighter} wallet={wallet} />}
					/>
					<Route path="/inventory" element={<InventoryPage fighter={fighter} account={account} />} />
					<Route path="/lobby" element={<LobbyPage fighter={fighter} />} />
					<Route path="/l" element={<LobbyPage fighter={fighter} />} />
					<Route path="/combat/:id" element={<CombatPage fighter={fighter} />} />
					<Route path="/landing" element={<LandingPage fighter={fighter} />} />
					<Route path="/market" element={<MarketPage fighter={fighter} />} />
					<Route path="/shop" element={<ShopPage fighter={fighter} />} />
					<Route path="/sponsor" element={<SponsorPage fighter={fighter} />} />
					<Route path="/history" element={<HistoryPage fighter={fighter} />} />
					<Route path="/aff" element={<AffiliatePage account={account} />} />

					<Route path="/leaderboard" element={<LeaderboardPage />} />
					<Route path="/chars" element={<CharactersPage />} />
					<Route path="/c" element={<CharactersPage />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</div>
			<ToastContainer position="bottom-right" theme="dark" hideProgressBar={false} autoClose={3000} />

			{isMenuOpen && (
				<MenuMobile
					onClose={() => {
						setIsMenuOpen(false);
					}}
					setFighter={setFighter}
				/>
			)}
		</div>
	);
};

export default App;
