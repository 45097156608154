// Credits.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { Button } from '../Button/Button';
import { Account, Wallet, Fund } from '../../store/types/fighterTypes';
import { Tabs } from '../Shared/Tabs';
import { QRCodeSVG } from 'qrcode.react';
import { eventCloud } from './../../eventCloud';

import { SignMessage } from '../../helpers/signerMessage';

import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';
import { ReactComponent as CreditsIconBlack } from '../../assets/icons/svg/credits.svg';

type CreditsProps = {
	account: Account | null;
	wallet: Wallet | null;
};

export const Credits = forwardRef<HTMLDivElement, CreditsProps>(({ account, wallet, ...rest }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedTab, setSelectedTab] = useState('Buy');
	const [sellAmount, setSellAmount] = useState<number | string>(0);
	const [withdrawAddress, setWithdrawAddress] = useState<string>('');
	const [funds, setFunds] = useState<Fund[]>([]);

	const [ethValue, setEthValue] = useState('');
	const [usdValue, setUsdValue] = useState('');
	const [creditsValue, setCreditsValue] = useState('');

	useEffect(() => {
		const handleFundsHistory = (newFunds: Fund[]) => {
			console.log('handleFundsHistory', newFunds);
			setFunds(newFunds);
		};

		eventCloud.on('funds_history', handleFundsHistory);

		const handleOpenCredits = () => {
			console.log('[handleOpenCredits]');
			setIsOpen(true);
		};

		eventCloud.on('open_credits', handleOpenCredits);

		return () => {
			eventCloud.off('funds_history', handleFundsHistory);
			eventCloud.off('open_credits', handleOpenCredits);
		};
	}, []);

	useEffect(() => {
		if (!account) {
			return;
		}
		setSellAmount(account?.credits);
	}, [account]);

	const handleWithdraw = async () => {
		if (!wallet) {
			console.error('Wallet is null');
			return;
		}

		const timestamp = Math.floor(Date.now() / 1000);

		// Create a readable message to sign
		const readableMessage = `[COMBATS.GAME] Sell ${sellAmount} CR to destination address ${withdrawAddress}. Timestamp: ${timestamp}.`;

		const signatureResult = await SignMessage(readableMessage, wallet);

		if (!signatureResult) {
			console.error('Signature process was not completed.');
			return false;
		}

		const { v, r, s } = signatureResult;

		// Call the eventCloud's requestWithdraw method
		eventCloud.requestWithdraw(Number(sellAmount), withdrawAddress, readableMessage, timestamp, v, r, s);

		setSellAmount('');
		setWithdrawAddress('');
		//setSelectedTab('History');
	};

	const handleTabSelected = (tab: string) => {
		setSelectedTab(tab);

		if (tab == 'Buy' && account?.deposit_address == '') {
			eventCloud.requestDepositAddress();
		} else if (tab == 'History') {
			eventCloud.requestFundsHistory();
		}
	};

	// 0x6705FBA5B7EC5b450b7eA473d3b35563E5DC8E98
	// 0x4042AF7F88205355B6f886c3179a5c7645ad4084
	// 0x64Bdb02A404430dea3Fa79f0EDccbc70D2fc68Ef

	return (
		<>
			<div className="w-full">
				<div
					className="relative active:top-px cursor-pointer w-full text-xxs px-1 flex bg-blue-550 rounded-sm py-2 justify-center text-black space-x-1 items-center font-bold"
					onClick={() => {
						setIsOpen(true);
					}}
				>
					<div>
						<CreditsIconBlack width={18} height={18} />
					</div>
					<div>{shortenNumber(Number(account?.credits))}</div>
				</div>
			</div>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					setIsOpen(false);
				}}
				contentLabel="Create New Fighter"
				className="w-full md:w-[560px] min-h-[450px] mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="text-xxs md:text-sm h-full flex flex-col">
					<div className="">
						<div className="flex items-center">
							<div className="flex-grow">
								<span className="font-regular text-m mb-4 select-none">Credits</span>
							</div>
							<div
								className="cursor-pointer"
								onClick={() => {
									setIsOpen(false);
								}}
							>
								<CloseIcon width={18} height={18} />
							</div>
						</div>
						<div className="mt-4 text-xxs">
							Total balance:{' '}
							<span className="text-blue-550 font-bold">
								{formatNumLocale(Number(account?.credits), 0)} CR
							</span>
						</div>
						<div className="mt-4">
							<Tabs
								selectedItem={selectedTab}
								items={['Buy', 'History']}
								onSelect={(tab: string) => {
									handleTabSelected(tab);
								}}
							/>
						</div>
					</div>
					<div className="flex-grow">
						{selectedTab == 'Sell' && (
							// <div className="p-4 text-center">
							// 	Credits sales disabled
							// </div>
							<div className="text-xxs text-gray-200 mt-4 h-full flex flex-col">
								<div className="flex flex-col flex-grow">
									<div className="flex justify-between">
										<div className="text-white">AMOUNT</div>
										<div>
											BALANCE:{' '}
											<span
												className="text-blue-550 font-bold font-mono text-xs cursor-pointer select-none"
												onClick={() => {
													setSellAmount(Number(account?.credits));
												}}
											>
												{account?.credits} CR
											</span>
										</div>
									</div>

									<div className="relative mt-2">
										<input
											type="text"
											value={sellAmount}
											onChange={(e) =>
												setSellAmount(e.target.value === '' ? '' : Number(e.target.value))
											}
											className="w-full px-10 py-3 rounded-sm text-xs placeholder-gray-200 bg-gray-550 font-bold text-blue-550 appearance-none outline-none"
										/>
										<CreditsIcon
											className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-200"
											width={18}
											height={18}
										/>
									</div>

									<div className="mt-4 text-white">
										<div>
											DESTINATION <span className="text-blue-550 font-bold">ETH</span> ADDRESS
										</div>
									</div>

									<div className="mt-2">
										<input
											type="text"
											value={withdrawAddress}
											onChange={(e) =>
												setWithdrawAddress(e.target.value === '' ? '' : e.target.value)
											}
											placeholder="0x..."
											className="w-full px-1 md:px-4 py-1 text-center md:py-3 rounded-sm text-xxs md:text-xs placeholder-gray-200 bg-gray-550 font-bold text-blue-550 appearance-none outline-none"
										/>
									</div>

									<div className="mt-4 p-2 border border-gray-550 bg-gray-550 bg-opacity-50 text-white">
										Credits are being converted to <span className="font-bold">ETH</span> at the
										rate of <span className="text-blue-550 font-bold">10000 CR = 1 USD</span> and
										sent to the wallet address on the{' '}
										<span className="text-red-550 font-bold">OP Mainnet [Optimism]</span>
									</div>
									<div className="mt-4 p-2 border border-gray-550 bg-gray-550 bg-opacity-50 text-red-550 text-sm text-center">
										Credits can be sold starting <strong>LEVEL 20</strong>
									</div>
								</div>

								<div className="mb-10">
									<div className="mt-6 text-white flex justify-between text-sm">
										<div className="text-gray-200">Converted Amount</div>
										<div className="text-blue-550  font-mono">
											{(Number(sellAmount) / 10000 / Number(eventCloud.ethPrice)).toFixed(10)} ETH
										</div>
									</div>

									<div className="mt-1 text-white flex justify-between text-sm">
										<div className="text-gray-200">Transaction Fee</div>
										<div className="text-blue-550 font-mono">
											{Number(eventCloud.transactionFee).toFixed(10)} ETH
										</div>
									</div>
									<div className="mt-1 text-white flex justify-between text-sm">
										<div className="text-gray-200">You will receive</div>
										<div className="text-blue-550  font-mono">
											{(
												Number(sellAmount) / 10000 / Number(eventCloud.ethPrice) -
												Number(eventCloud.transactionFee)
											).toFixed(10)}{' '}
											ETH
										</div>
									</div>

									<div className="mt-4">
										<Button onClick={handleWithdraw}>CONFIRM</Button>
									</div>
								</div>
							</div>
						)}

						{selectedTab == 'Buy' && (
							<div className=" text-gray-200 mt-4 mb-4 min-h-[500px]">
								<div className="mt-4 p-2 border border-gray-550 bg-gray-550 bg-opacity-50 text-white">
									To buy credits, send <span className="font-bold text-blue-550">ETH</span> to your
									personal deposit address below on the{' '}
									<span className="text-red-550 font-bold">Optimism Mainnet</span>. All{' '}
									<span className="font-bold text-blue-550">ETH</span> received will be converted to
									in-game credits at the rate of{' '}
									<span className="text-blue-550 font-bold">1 USD = 10000 CR</span> and added to your
									balance
								</div>

								{!account?.deposit_address ? (
									<div className="flex justify-center text-center mt-10">
										Generating deposit address...
									</div>
								) : (
									<>
										<div className="flex justify-center mt-4 space-x-2 md:space-x-10">
											<div className="mx-1 md:mx-4">
												<QRCodeSVG value={account?.deposit_address} size={150} level={'Q'} />
											</div>
											<div className="flex-grow flex flex-col space-y-2">
												<div className=" flex justify-between">
													<div className="text-white">CALCULATOR</div>
													<div className="font-mono text-blue-550">
														1 ETH = {eventCloud.ethPrice} USD
													</div>
												</div>
												<div className="relative">
													<input
														type="text"
														placeholder="0"
														value={ethValue}
														onChange={(e) => {
															setEthValue(e.target.value);
															setCreditsValue(
																Math.floor(
																	Number(e.target.value) * eventCloud.ethPrice * 10000
																).toFixed(0)
															);
														}}
														className="font-mono w-full px-4 py-3 rounded-sm text-sm placeholder-gray-200 bg-gray-550 text-white appearance-none outline-none"
													/>
													<div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-white font-bold">
														ETH
													</div>
												</div>
												<div>TO</div>
												<div className="relative">
													<input
														type="text"
														placeholder="0"
														value={creditsValue}
														onChange={(e) => {
															setCreditsValue(e.target.value);
															setEthValue(
																(
																	Number(e.target.value) /
																	10000 /
																	eventCloud.ethPrice
																).toFixed(8)
															);
														}}
														className="font-mono w-full px-4 py-3 rounded-sm text-sm placeholder-gray-200 bg-gray-550 text-white appearance-none outline-none"
													/>
													<CreditsIcon
														className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-200"
														width={18}
														height={18}
													/>
												</div>
											</div>
										</div>

										<div className="mt-4">
											<div className="ml-8">DEPOSIT ADDRESS</div>
										</div>
										<div className="mt-2">
											<input
												type="text"
												value={account?.deposit_address}
												disabled
												className="w-full px-1 md:px-4 py-1 md:py-3 rounded-sm text-center text-xxs md:text-sm font-bold  bg-gray-550 text-white appearance-none outline-none"
											/>
										</div>

										<div className="mt-4 text-white">
											Send funds on the{' '}
											<span className="text-red-550 font-bold">Optimism Mainnet</span>. If you
											sent funds on a different network, please contact support to finish your
											purchase manually.
										</div>
										<div className="mt-4 text-white">
											If you sent a smaller amount, make another transfer and bring the total
											amount higher than the minimum deposit amount. The total amount will be
											automatically processed.
										</div>

										<div className="mt-4 text-white">If you have any issues, contact support.</div>
									</>
								)}
							</div>
						)}

						{selectedTab == 'History' && (
							<div className="h-full text-xxs overflow-x-auto">
								{/* Header */}
								<div className="text-gray-200 mt-1 mb-4 flex md:min-w-[480px]">
									<div className="pr-2 w-[50px]">Type</div>
									<div className="pr-2 w-[80px]">Credits</div>
									<div className="pr-2 w-[120px]">ETH</div>
									<div className="pr-2 w-[80px]">Time</div>
									<div className="flex-grow text-right">Transaction</div>
								</div>

								{/* Scrollable Content */}
								<div className="max-h-[460px] overflow-y-auto">
									{!funds || funds?.length === 0 ? (
										<div className="flex justify-center text-gray-200 mt-10">Nothing to show</div>
									) : (
										<div className="min-w-[480px]">
											{funds?.map((fund, index) => (
												<div key={index} className="text-white mt-1 mb-4 flex min-w-[480px]">
													<div
														className={`pr-2 w-[50px] ${fund.type === 'BUY' ? 'text-blue-550' : 'text-red-550'}`}
													>
														{fund.type}
													</div>
													<div className="pr-2 w-[80px]">{fund.amount}</div>
													<div className="pr-2 w-[120px]">
														{fund.amount_eth.toFixed(8)} ETH
													</div>
													<div className="pr-2 w-[100px] text-gray-200">
														{formatTimestampToLocaleTime(fund.created_at)}
													</div>
													<div className="flex-grow text-right text-blue-550">
														<a
															rel="noreferrer"
															target="_blank"
															href={`https://optimistic.etherscan.io/tx/${fund.tx_hash}`}
														>
															{toShortenedAddress(fund.tx_hash)}
														</a>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
});

const formatNumLocale = (price: number, decimals: number): string => {
	return new Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(price);
};

function shortenNumber(num: number) {
	if (num < 1000) {
		return num.toString();
	} else if (num >= 1000 && num < 10000) {
		return (num / 1000).toFixed(1) + 'K';
	} else if (num >= 10000 && num < 1000000) {
		return Math.round(num / 1000) + 'K';
	} else if (num >= 1000000 && num < 10000000) {
		return (num / 1000000).toFixed(1) + 'M';
	} else if (num >= 10000000) {
		return Math.round(num / 1000000) + 'M';
	}
}

const toShortenedAddress = (a: string) => {
	return `${a.slice(0, 7)}…${a.slice(-5)}`;
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		day: '2-digit',
		month: '2-digit',
		year: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

Credits.displayName = 'Credits';
