import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Fighter, Item, Round, Combat } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { HealthManaIndicator } from './HealthManaIndicator';
import FighterStats from '../Fighter/FighterStats';
import { ItemPopover } from '../Item/ItemPopover';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import '../../css/Fighter.css';

import { ReactComponent as PlaceholderHelmet } from '../../assets/icons/svg/placeholder-helmet.svg';
import { ReactComponent as PlaceholderArmor } from '../../assets/icons/svg/placeholder-armor.svg';
import { ReactComponent as PlaceholderGloves } from '../../assets/icons/svg/placeholder-gloves.svg';
import { ReactComponent as PlaceholderShield } from '../../assets/icons/svg/placeholder-weapon.svg';
import { ReactComponent as PlaceholderBoots } from '../../assets/icons/svg/placeholder-boots.svg';
import { ReactComponent as PlaceholderWeapon } from '../../assets/icons/svg/placeholder-weapon.svg';
import { ReactComponent as PlaceholderPants } from '../../assets/icons/svg/placeholder-pants.svg';
import { ReactComponent as PlaceholderRing } from '../../assets/icons/svg/placeholder-ring.svg';
import { ReactComponent as PlaceholderPendant } from '../../assets/icons/svg/placeholder-pendant.svg';
import { ReactComponent as PlaceholderEnigma } from '../../assets/icons/svg/enigma.svg';
import { ReactComponent as SponsorCrown } from '../../assets/icons/svg/sponsor-crown.svg';

import { ReactComponent as HeadIcon } from '../../assets/icons/svg/head.svg';
import { ReactComponent as HeadGrayIcon } from '../../assets/icons/svg/head-gray.svg';
import { ReactComponent as ChestIcon } from '../../assets/icons/svg/chest.svg';
import { ReactComponent as LegsIcon } from '../../assets/icons/svg/legs.svg';

import { Tooltip } from 'react-tooltip';

interface FighterProfileProps {
	fighter: Fighter | null;
	equippable: boolean;
	hideStats?: boolean;
	borderColor?: string;
	statsUpdateable?: boolean;
	allowItemPopover?: boolean;
	hideHealthMana?: boolean;
	hideHealth?: boolean;
	statsVertical?: boolean;
	showManaNumber?: boolean;
	showHealthNumber?: boolean;
	showItems?: boolean;
	hideName?: boolean;

	showItemLevels?: boolean;
	onAttackRegionSelect?: (region: string) => void;
	onDefenceRegionSelect?: (region: string) => void;
	selectedAttack?: string;
	selectedDefence?: string;
	closedRound?: Round | null;
	fighterSide?: string;
	combat?: Combat;
	autoHit?: boolean;
}

const FighterProfile: React.FC<FighterProfileProps> = ({
	fighter,
	equippable,
	hideStats = true,
	borderColor = 'blue-550',
	statsUpdateable,
	allowItemPopover = false,
	hideHealthMana,
	hideHealth = false,
	statsVertical = false,
	showManaNumber = true,
	showItems = true,
	showHealthNumber = true,
	hideName = false,
	showItemLevels = true,
	onAttackRegionSelect,
	onDefenceRegionSelect,
	selectedAttack,
	selectedDefence,
	closedRound,
	fighterSide,
	combat,
	autoHit,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Item | null>(null);
	const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
	const [hitScreenVisible, setHitScreenVisible] = useState(false);

	const [dmg, setDmg] = useState(0);
	const [dmgType, setDmgType] = useState('Basic');
	const [dmgRegion, setDmgRegion] = useState('');

	const [activeHits, setActiveHits] = useState<{ dmg: number; dmgType: string; dmgRegion: string; id: number }[]>([]);
	const [hitCounter, setHitCounter] = useState(0);

	const [highlightSelectors, setHighlightSelectors] = useState(false);

	useEffect(() => {
		if (!closedRound) {
			return;
		}

		setHitCounter((prevCounter) => prevCounter + 1); // Increment counter
		const newHit = {
			dmg: fighterSide === 'maker' ? closedRound?.taker_damage : closedRound?.maker_damage,
			dmgType: fighterSide === 'maker' ? closedRound?.taker_damage_type : closedRound?.maker_damage_type,
			dmgRegion: fighterSide === 'maker' ? closedRound?.taker_attack : closedRound?.maker_attack,
			id: hitCounter + 1,
		};

		setActiveHits((prevHits) => [...prevHits, newHit]);

		// Set up a timeout to remove this hit from the list after animation duration
		setTimeout(() => {
			setActiveHits((prevHits) => prevHits.filter((hit) => hit.id !== newHit.id));
		}, 3000); // Adjust as needed
	}, [closedRound, fighterSide]); // Only these should trigger the effect

	useEffect(() => {
		const handleHighlightSelectorsAttack = () => {
			if (!onAttackRegionSelect) return;
			setHighlightSelectors(true);

			// After animation duration, revert back to original state if required
			setTimeout(() => {
				//selector.classList.remove('border-opacity-100');
				setHighlightSelectors(false);
			}, 500); // Match with animation duration
		};

		eventCloud.on('highlight_selectors_attack', handleHighlightSelectorsAttack);

		const handleHighlightSelectorsDefence = () => {
			if (!onDefenceRegionSelect) return;
			setHighlightSelectors(true);

			// After animation duration, revert back to original state if required
			setTimeout(() => {
				//selector.classList.remove('border-opacity-100');
				setHighlightSelectors(false);
			}, 500); // Match with animation duration
		};

		eventCloud.on('highlight_selectors_defence', handleHighlightSelectorsDefence);

		return () => {
			eventCloud.off('highlight_selectors_attack', handleHighlightSelectorsAttack);
			eventCloud.off('highlight_selectors_defence', handleHighlightSelectorsDefence);
		};
	}, []);

	if (!fighter) {
		return null;
	}

	const handleUnequipItem = (slot: string) => {
		eventCloud.unequipItem(slot);
	};

	const isAttackSubmitted = (): boolean => {
		if (!combat || !combat.active_round) return false;
		//console.log("[isAttackSubmitted]",eventCloud.selectedFighter, combat.taker.id, combat.active_round.taker_submitted )

		if (combat.maker_id == eventCloud.selectedFighter?.id && combat.active_round.maker_submitted) {
			//console.log("[isAttackSubmitted] true")
			return true;
		}

		if (combat.taker_id == eventCloud.selectedFighter?.id && combat.active_round.taker_submitted) {
			//console.log("[isAttackSubmitted] true")
			return true;
		}

		return false;
	};

	const renderEquipmentSlot = (
		equipment: Item | null,
		placeholder: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
		slotName: string,
		align: 'justify-start' | 'justify-end'
	) => {
		return (
			<div className={`h-[calc(20%-0.2rem)]`}>
				<div className=" h-full  aspect-[2/3]">
					{!showItems ? (
						<div className=" h-full aspect-[2/3] border border-gray-550 rounded-sm flex items-center justify-center text-gray-550">
							{React.createElement(PlaceholderEnigma, {
								className: 'w-2/3 h-2/3',
							})}
						</div>
					) : equipment ? (
						<ItemThumbnail
							item={equipment}
							showLevel={showItemLevels}
							onClick={(e: any) => {
								setAnchorEl(e.currentTarget);
								setSelectedItem(equipment);
								setSelectedSlot(slotName);
							}}
						/>
					) : (
						<div className=" h-full aspect-[2/3] border border-gray-550 rounded-sm flex items-center justify-center">
							{React.createElement(placeholder, {
								className: 'w-2/3 h-2/3',
							})}
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col gap-y-2 text-white bg-black overflow-hidden w-full">
			{!hideHealthMana && (
				<div className="flex gap-x-2">
					{!hideName && (
						<div className="flex flex-grow gap-x-1 bg-gray-550 items-center px-2 font-bold rounded-sm text-xxxs lg:text-xs">
							<div>{fighter.name}</div>
							<div className="text-blue-550">[{fighter.level}]</div>
							{fighter?.sponsor_expires && new Date(fighter.sponsor_expires) > new Date() && (
								<div className="cursor-pointer">
									<SponsorCrown
										width={13}
										height={13}
										data-tooltip-id="sponsor-tooltip"
										data-tooltip-content="VIP"
									/>

									<Tooltip id="sponsor-tooltip" place="top" />
								</div>
							)}
						</div>
					)}
					{!hideHealth && (
						<div className="flex-grow w-1/2">
							<HealthManaIndicator
								fighter={fighter}
								showManaNumber={showManaNumber}
								showHealthNumber={showHealthNumber}
							/>
						</div>
					)}
				</div>
			)}

			<div className="relative flex justify-between w-full h-full overflow-hidden">
				{/* Avatar image positioned on top */}

				{/* Left equipment column */}
				<div className="flex flex-col gap-y-1 overflow-hidden h-full max-h-full w-[calc(20%)] z-0">
					{renderEquipmentSlot(fighter.equipment.helmet, PlaceholderHelmet, 'Helmet', 'justify-start')}
					{renderEquipmentSlot(fighter.equipment.gloves, PlaceholderGloves, 'Gloves', 'justify-start')}
					{renderEquipmentSlot(fighter.equipment.shield, PlaceholderShield, 'Shield', 'justify-start')}
					{renderEquipmentSlot(fighter.equipment.boots, PlaceholderBoots, 'Boots', 'justify-start')}
					{renderEquipmentSlot(fighter.equipment.left_ring, PlaceholderRing, 'Left Ring', 'justify-start')}
				</div>

				{/*<div className="flex-grow w-full">&nbsp;</div>*/}

				{/* Right equipment column */}
				<div className="overflow-hidden max-h-full w-[calc(20%)] z-0">
					<div className="flex flex-col gap-y-1 h-full max-h-full">
						{renderEquipmentSlot(fighter.equipment.pendant, PlaceholderPendant, 'Pendant', 'justify-end')}
						{renderEquipmentSlot(fighter.equipment.armor, PlaceholderArmor, 'Armor', 'justify-end')}
						{renderEquipmentSlot(fighter.equipment.weapon, PlaceholderWeapon, 'Weapon', 'justify-end')}
						{renderEquipmentSlot(fighter.equipment.pants, PlaceholderPants, 'Pants', 'justify-end')}
						{renderEquipmentSlot(
							fighter.equipment.right_ring,
							PlaceholderRing,
							'Right Ring',
							'justify-end'
						)}
					</div>
				</div>
				<img
					src={`/assets/AVATAR_${fighter.class}.png`}
					alt="Avatar"
					className="select-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[calc(65%)] h-auto max-h-full aspect-[1/2]"
				/>

				{activeHits.map((hit) => (
					<div
						key={hit.id}
						className="select-none absolute px-2 md:px-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full max-w-[calc(65%)]"
					>
						<div className="w-full h-full">
							<div className="w-full h-full flex flex-col gap-y-1 md:gap-y-2">
								<div className={classNames(`relative flex h-1/3 p-2 rounded-md cursor-pointer`)}>
									{hit.dmgRegion === 'Head' && (
										<div
											className={classNames(
												`absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center text-4xl xl:text-10xl font-bold`
											)}
										>
											{/* Background Layer */}
											<div
												className={classNames(
													`absolute top-0 left-0 bottom-0 right-0 ${hit.dmg === 0 ? 'bg-gray-550' : hit.dmgType === 'Basic' ? 'bg-yellow-500' : 'bg-blue-550'} bg-opacity-100 animate-bg-fade`
												)}
											></div>

											{/* Sliding Text */}
											<div
												className={classNames(`relative animate-slide-up text-white`)}
												style={{ WebkitTextStroke: '1px black' }}
											>
												{hit.dmg === 0 ? 'Miss' : '-' + hit.dmg}
											</div>
										</div>
									)}
								</div>

								<div className={classNames(`relative flex h-1/3 p-2 rounded-md cursor-pointer`)}>
									{hit.dmgRegion === 'Chest' && (
										<div
											className={classNames(
												`absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center text-4xl xl:text-10xl font-bold`
											)}
										>
											{/* Background Layer */}
											<div
												className={classNames(
													`absolute top-0 left-0 bottom-0 right-0 ${hit.dmg === 0 ? 'bg-gray-550' : hit.dmgType === 'Basic' ? 'bg-yellow-500' : 'bg-blue-550'} bg-opacity-100 animate-bg-fade`
												)}
											></div>

											{/* Sliding Text */}
											<div
												className={classNames(`relative animate-slide-up text-white`)}
												style={{ WebkitTextStroke: '1px black' }}
											>
												{hit.dmg === 0 ? 'Miss' : '-' + hit.dmg}
											</div>
										</div>
									)}
								</div>

								<div className={classNames(`relative flex h-1/3 p-2 rounded-md cursor-pointer`)}>
									{hit.dmgRegion === 'Legs' && (
										<div
											className={classNames(
												`absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center text-4xl xl:text-10xl font-bold`
											)}
										>
											{/* Background Layer */}
											<div
												className={classNames(
													`absolute top-0 left-0 bottom-0 right-0 ${hit.dmg === 0 ? 'bg-gray-550' : hit.dmgType === 'Basic' ? 'bg-yellow-500' : 'bg-blue-550'} bg-opacity-100 animate-bg-fade`
												)}
											></div>

											{/* Sliding Text */}
											<div
												className={classNames(`relative animate-slide-up text-white`)}
												style={{ WebkitTextStroke: '1px black' }}
											>
												{hit.dmg === 0 ? 'Miss' : '-' + hit.dmg}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				))}

				{highlightSelectors && (
					<div className="select-none absolute px-2 md:px-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full max-w-[calc(65%)]">
						<div className="w-full h-full">
							<div className="w-full h-full flex flex-col gap-y-1 md:gap-y-2">
								<div
									className={classNames(
										`relative flex h-1/3 p-2 rounded-md cursor-pointer border-2 border-pulse-animation ${onAttackRegionSelect ? 'border-red-550' : onDefenceRegionSelect && 'border-blue-550'}`
									)}
								></div>
								<div
									className={classNames(
										`relative flex h-1/3 p-2 rounded-md cursor-pointer border-2 border-pulse-animation ${onAttackRegionSelect ? 'border-red-550' : onDefenceRegionSelect && 'border-blue-550'}`
									)}
								></div>
								<div
									className={classNames(
										`relative flex h-1/3 p-2 rounded-md cursor-pointer border-2 border-pulse-animation ${onAttackRegionSelect ? 'border-red-550' : onDefenceRegionSelect && 'border-blue-550'}`
									)}
								></div>
							</div>
						</div>
					</div>
				)}

				{!combat?.closed && combat?.active_round && onAttackRegionSelect && (
					<div className="select-none absolute px-2 md:px-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full max-w-[calc(65%)]">
						<div className="w-full h-full">
							<div className="w-full h-full flex flex-col gap-y-1 md:gap-y-2">
								<div
									className={classNames(
										`attack-selector relative flex h-1/3 p-2 border border-red-550 rounded-md border-2 cursor-pointer bg-red-550 border-red-550 border-2 ${selectedAttack == 'Head' ? 'bg-opacity-0' : selectedAttack == '' ? 'animate-pulsate-bg-red border-opacity-0' : 'bg-opacity-10 border-opacity-0'}`
									)}
									onClick={() => {
										onAttackRegionSelect('Head');
									}}
								>
									<div
										className={classNames(
											`w-[20px] h-[20px] md:w-[50px] md:h-[50px] p-0.5 md:p-1 rounded-sm ${selectedAttack == 'Head' ? 'bg-red-550 text-black ' : 'bg-black text-gray-200 '}`
										)}
									>
										<HeadIcon className="w-full h-full" />
									</div>
								</div>
								<div
									className={classNames(
										`attack-selector relative flex h-1/3 p-2 border border-red-550 rounded-md border-2 cursor-pointer bg-red-550 border-red-550 border-2 ${selectedAttack == 'Chest' ? 'bg-opacity-0' : selectedAttack == '' ? 'animate-pulsate-bg-red border-opacity-0' : 'bg-opacity-10 border-opacity-0'}`
									)}
									onClick={() => {
										onAttackRegionSelect('Chest');
									}}
								>
									<div
										className={classNames(
											`w-[20px] h-[20px] md:w-[50px] md:h-[50px] p-0.5 md:p-1 rounded-sm ${selectedAttack == 'Chest' ? 'bg-red-550 text-black ' : 'bg-black text-gray-200 '}`
										)}
									>
										<ChestIcon className="w-full h-full" />
									</div>
								</div>
								<div
									className={classNames(
										`attack-selector relative flex h-1/3 p-2 border border-red-550 rounded-md border-2 cursor-pointer bg-red-550 border-red-550 border-2 ${selectedAttack == 'Legs' ? 'bg-opacity-0' : selectedAttack == '' ? 'animate-pulsate-bg-red border-opacity-0' : 'bg-opacity-10 border-opacity-0'} `
									)}
									onClick={() => {
										onAttackRegionSelect('Legs');
									}}
								>
									<div
										className={classNames(
											`w-[20px] h-[20px] md:w-[50px] md:h-[50px] p-0.5 md:p-1 rounded-sm ${selectedAttack == 'Legs' ? 'bg-red-550 text-black ' : 'bg-black text-gray-200 '}`
										)}
									>
										<LegsIcon className="w-full h-full" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{!combat?.closed && combat?.active_round && onDefenceRegionSelect && (
					<div className="select-none absolute px-2 md:px-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full max-w-[calc(65%)]">
						<div className="w-full h-full">
							<div className="w-full h-full flex flex-col gap-y-1 md:gap-y-2">
								<div
									className={classNames(
										`defence-selector relative flex h-1/3 p-2 justify-end border rounded-md cursor-pointer bg-blue-550 border-blue-550 border-2 ${selectedDefence == 'Head' ? 'bg-opacity-0' : selectedDefence == '' ? 'animate-pulsate-bg-blue bg-blue-550 border-opacity-0' : 'bg-opacity-10 border-opacity-0'}`
									)}
									onClick={() => {
										onDefenceRegionSelect('Head');
									}}
								>
									<div
										className={classNames(
											`w-[20px] h-[20px] md:w-[50px] md:h-[50px] p-0.5 md:p-1 rounded-sm ${selectedDefence == 'Head' ? 'bg-blue-550 text-black ' : 'bg-black text-gray-200 '}`
										)}
									>
										<HeadIcon className="w-full h-full" />
									</div>
								</div>
								<div
									className={classNames(
										`defence-selector relative flex h-1/3 p-2 justify-end border rounded-md cursor-pointer bg-blue-550 border-blue-550 border-2 ${selectedDefence == 'Chest' ? 'bg-opacity-0' : selectedDefence == '' ? 'animate-pulsate-bg-blue border-opacity-0 bg-blue-550' : 'bg-opacity-10 border-opacity-0'}`
									)}
									onClick={() => {
										onDefenceRegionSelect('Chest');
									}}
								>
									<div
										className={classNames(
											`w-[20px] h-[20px] md:w-[50px] md:h-[50px] p-0.5 md:p-1 rounded-sm ${selectedDefence == 'Chest' ? 'bg-blue-550 text-black ' : 'bg-black text-gray-200 '}`
										)}
									>
										<ChestIcon className="w-full h-full" />
									</div>
								</div>
								<div
									className={classNames(
										`defence-selector relative flex h-1/3 p-2 justify-end border rounded-md cursor-pointer bg-blue-550 border-blue-550 border-2 ${selectedDefence == 'Legs' ? 'bg-opacity-0' : selectedDefence == '' ? 'animate-pulsate-bg-blue border-opacity-0 bg-blue-550' : 'bg-opacity-10 border-opacity-0'}`
									)}
									onClick={() => {
										onDefenceRegionSelect('Legs');
									}}
								>
									<div
										className={classNames(
											`w-[20px] h-[20px] md:w-[50px] md:h-[50px] p-0.5 md:p-1 rounded-sm ${selectedDefence == 'Legs' ? 'bg-blue-550 text-black ' : 'bg-black text-gray-200 '}`
										)}
									>
										<LegsIcon className="w-full h-full" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{isAttackSubmitted() && (
					<div className="absolute inset-0 bg-gray-550 bg-opacity-75 z-10 flex flex-col items-center justify-center text-center">
						<div className="text-xs lg:text-xl xl:text-2xl text-green-500 font bold">MOVE SUBMITTED!</div>
						<div className="text-xs lg:text-xl mt-4">AWAITING OPPONENT</div>
					</div>
				)}
			</div>

			{allowItemPopover && (
				<ItemPopover
					selectedItem={selectedItem}
					anchorEl={anchorEl}
					fighter={fighter}
					handleUnequipItem={equippable ? handleUnequipItem : undefined}
					slot={selectedSlot}
					onClose={() => {
						setAnchorEl(null);
						setSelectedItem(null);
						setSelectedSlot(null);
					}}
				/>
			)}

			{!hideStats && (
				<div>
					<FighterStats updateable={statsUpdateable} fighter={fighter} vertical={statsVertical} />
				</div>
			)}
		</div>
	);
};

export default FighterProfile;
