import React, { useState, useRef } from 'react';
import { Item } from '../../store/types/fighterTypes';
import './Spinwheel.css';
import { Button } from '../Button/Button';
import { ItemThumbnail } from '../Item/ItemThumbnail';	
import { ItemName } from '../Shared/ItemName';


interface SpinwheelProps {
	spinwheel_items: Item[] | null;
	dropped_item: Item | null;
	setFireConfetti: (val: boolean) => void;
	onClose: () => void;
}

const Spinwheel: React.FC<SpinwheelProps> = ({ spinwheel_items, dropped_item, setFireConfetti, onClose }) => {
	const spinwheelRef = useRef<HTMLDivElement>(null);
	const [isSpinning, setIsSpinning] = useState(false); // State to track spinning
	const [spinningComplete, setSpinningComplete] = useState(false); // State to track if spinning is complete

	const spinWheel = () => {
	    const wheel = spinwheelRef.current;
	    if (!wheel || !spinwheel_items || !dropped_item) return;

	    const items = spinwheel_items;
	    const droppedItemName = dropped_item.name;

	    // Find the index of the dropped item in spinwheel_items
	    const position = items.findIndex(item => item.name === droppedItemName);

	    console.warn("Dropped item", droppedItemName, position, items.length);
	    // Ensure position is within bounds; if not, default to 0
	    if (position === -1) {
	        console.warn("Dropped item not found in spinwheel items.");
	        return;
	    }

	    const cardWidth = 136;

	    const rows = 11;
	    const card = cardWidth; // Card height + margin
	    let landingPosition = rows * items.length * card + position * card;

	    const randomize = Math.floor(Math.random() * cardWidth) - cardWidth / 2;
	    landingPosition += randomize;

	    const x = Math.random() * 0.5;
	    const y = Math.random() * 0.2;

	    wheel.style.transitionTimingFunction = `cubic-bezier(0, ${x}, ${y}, 1)`;
	    wheel.style.transitionDuration = "6s";
	    wheel.style.transform = `translate3d(-${landingPosition}px, 0px, 0px)`;

	    // Set spinning state to true and reset after the animation
	    setIsSpinning(true);
	    setSpinningComplete(false); // Reset spinning complete status
	    setTimeout(() => {
	        // wheel.style.transitionTimingFunction = "";
	        // wheel.style.transitionDuration = "";
	        // const resetTo = -(position * card + randomize);
	        // wheel.style.transform = `translate3d(${resetTo}px, 0px, 0px)`;

	        // Reset spinning state and button text
	        setIsSpinning(false);
	        setSpinningComplete(true); // Set spinning complete status
			setFireConfetti(true);
	    }, 3000);
	};



	if (!spinwheel_items || !dropped_item) return null;

	return (
		<div
			className="relative w-full bg-cover bg-center"
			
		>
			
			<div>
				<div className="text-sm text-gray-200 mb-2">
					<div>			
						<div>
							{!spinningComplete && (
								<>
									<div className="font-bold text-xl text-center text-white">
										Spin the Wheel
									</div>
									<div className="spinwheel-component my-8">
										<div className="roulette-wrapper">
											<div className="selector bg-blue-550"></div>
											<div className="wheel" ref={spinwheelRef}>
												<div className="row">						
													{Array.from({ length: 29 }).map((_, rowIndex) => (
														<div className="row" key={rowIndex}>
															{spinwheel_items.map((item, index) => (
																<div
																	key={index}
																	className="card w-[136px] h-[201px]"
																>
																	<ItemThumbnail width={136} height={201} item={item} />
																</div>
															))}
														</div>
													))}
												</div>
											</div>
										</div>
									
										
										<div className="mt-5 w-full px-10">
											<Button
												size="large"
												variant={isSpinning ? "gray" : "black"} // Change variant based on spinning state
												onClick={() => spinWheel()}
												disabled={isSpinning} // Disable button while spinning
											>
												SPIN WHEEL
											</Button>
										</div>
									</div>
								</>
						)}

							{spinningComplete && dropped_item && (
								<div className="w-full flex justify-center items-center">
									<div className="text-center flex flex-col justify-between gap-y-2 items-center w-[200px]">
										<div className="text-white font-bold text-m text-center">
											<ItemName item={dropped_item} />
										</div>
										<div className="text-center flex flex-col items-center"> 
											<ItemThumbnail width={136} height={201} item={dropped_item} />
										</div>
										<Button
											size="large"
											variant="black" // Change variant based on spinning state
											onClick={onClose}
											disabled={isSpinning} // Disable button while spinning
										>
											CLOSE
										</Button>
									</div>
								</div>
							)}							
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Spinwheel;
