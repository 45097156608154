import React from 'react';
import classNames from 'classnames';
import { Item } from '../../store/types/fighterTypes';

interface ItemNameProps {
    item: Item;
    className?: string;
}

export const ItemName: React.FC<ItemNameProps> = ({ item, className = '' }) => {
    return (
        <div
            className={classNames(
                `font-bold text-xxxs sm:text-m ${(item.has_luck || item.has_skill) && 'text-blue-550'}`,
                className
            )}
        >
            {item.name}
            {item.level_upgradeable && ` +${item.level}`}
            {item.has_skill && ' +Skill'}
            {item.has_luck && ' +Luck'}
        </div>
    );
};

export default ItemName;