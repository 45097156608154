// src/eventCloud.ts

import React, { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject } from 'rxjs';
import { EventEmitter } from 'events';
import { toast } from 'react-toastify';
import { formatNotificationMessage } from './helpers/notificationHelper';
import { Account, Fighter, Combat, Item, Chat, MarketItem } from './store/types/fighterTypes';

class EventCloud extends EventEmitter {
	private static instance: EventCloud;
	private worker: Worker;

	public uniqueUserId: string;
	public backendTimestamp: string;
	public ethPrice: number;
	public transactionFee: number;
	public selectedAccount: Account | null;
	public selectedFighter: Fighter | null;
	public accountStore: BehaviorSubject<Account | null>;
	public fighterStore: BehaviorSubject<Fighter | null>;
	public userFightersStore: BehaviorSubject<Fighter[]>;
	public activeCombatsStore: BehaviorSubject<Record<string, Combat>>;
	public finishedCombatsStore: BehaviorSubject<Combat[]>;
	public shopItemsStore: BehaviorSubject<Item[]>;
	public marketHistoryStore: BehaviorSubject<MarketItem[]>;
	public chatBoxesStore: BehaviorSubject<Record<string, Chat>>;

	private constructor() {
		super();

		this.uniqueUserId = this.generateUniqueUserId();
		this.ethPrice = 0;
		this.transactionFee = 0;
		this.backendTimestamp = '';
		this.selectedAccount = null;
		this.selectedFighter = null;
		this.accountStore = new BehaviorSubject<Account | null>(null);
		this.fighterStore = new BehaviorSubject<Fighter | null>(null);
		this.userFightersStore = new BehaviorSubject<Fighter[]>([]);
		this.activeCombatsStore = new BehaviorSubject<Record<string, Combat>>({});
		this.finishedCombatsStore = new BehaviorSubject<Combat[]>([]);
		this.shopItemsStore = new BehaviorSubject<Item[]>([]);
		this.marketHistoryStore = new BehaviorSubject<MarketItem[]>([]);
		this.chatBoxesStore = new BehaviorSubject<Record<string, Chat>>({});

		this.worker = new Worker(new URL('./workers/socketWorker.worker.ts', import.meta.url));
		this.initWorker();
	}

	private initWorker() {
		// Listen for messages from the worker
		this.worker.onmessage = (event: MessageEvent) => {
			const data = event.data;
			this.emit(data.type, data.payload);
		};

		// Initialize the worker
		this.worker.postMessage({ type: 'INIT' });
		this.on('eth_price', this.handleEthPriceUpdate);
		this.on('shop_items', this.handleShopItems);
		this.on('notification', this.handleNotification);
		this.on('active_combats_update', this.handleCombatsUpdate);
		this.on('finished_combats', this.handleFinishedCombats);
		this.on('finished_combat_add', this.handleFinishedCombatAdd);
		this.on('account_update', this.handleAccountUpdate);
		this.on('fighter_update', this.handleFighterUpdate);
		this.on('user_fighters_update', this.handleUserFightersUpdate);
		this.on('chat_update', this.handleChatUpdate);
		this.on('combat_update', this.handleCombatUpdate);
		this.on('market_history', this.handleMarketHistoryUpdate);
		this.on('timestamp', this.handleBackendTimestamp);

		this.on('RETRY_CONNECTION', this.retryConnection); // Add this line
		this.on('selected_fighter', this.setSelectedFighter);
	}

	// private generateUniqueUserId(): string {
	// 	const existingId = localStorage.getItem("uuid");

	// 	if (!existingId) {
	// 		const uniqueId = uuidv4(); // Generate a unique integer based on the current timestamp
	// 		localStorage.setItem("uuid", uniqueId);
	// 		return uniqueId;
	// 	}

	// 	return existingId;
	// }

	private track(event: string, properties?: Record<string, any>) {
		window.heap.track(event, properties);
	}

	private generateUniqueUserId(): string {
		const existingId = localStorage.getItem('uuid');

		if (!existingId) {
			const uniqueId = uuidv4(); // Generate a unique identifier using uuid library
			localStorage.setItem('uuid', uniqueId);

			// Identify the user in Heap with this unique ID
			if (typeof window !== 'undefined' && window.heap) {
				window.heap.identify(uniqueId);
			}

			return uniqueId;
		} else {
			// Identify the user in Heap with the existing ID
			if (typeof window !== 'undefined' && window.heap) {
				window.heap.identify(existingId);
			}
			return existingId;
		}
	}

	private setSelectedFighter(fighter: Fighter) {
		if (fighter) {
			window.heap.addUserProperties({ Nickname: fighter.name });
			this.selectedFighter = fighter;
		} else {
			this.selectedFighter = null;
		}
	}

	private handleEthPriceUpdate(price: any) {
		console.log('[handleEthPriceUpdate]:', price);
		this.ethPrice = price.price;
		this.transactionFee = price.transaction_fee;
	}

	private retryConnection() {
		console.log('Retrying WebSocket connection...');
		// Terminate existing worker
		this.terminateWorker();

		// Create a new worker instance
		this.worker = new Worker(new URL('./workers/socketWorker.worker.ts', import.meta.url));
		this.initWorker();
	}

	public handleBackendTimestamp(data: any) {
		//console.warn('[handleBackendTimestamp]:', data);
		if (this.backendTimestamp == data) return;

		if (this.backendTimestamp == '') {
			this.backendTimestamp = data;
			return;
		}

		console.warn('Reload!');
		window.location.reload();
	}

	// Process messaged from backend
	public handleNotification(data: any) {
		console.log('[handleNotification]:', data);
		this.notify(formatNotificationMessage(data), data.type, data.delay);
	}

	private handleShopItems(items: Item[]) {
		// Get the combat with the matching id
		console.log('[handleShopItems]', items);
		this.shopItemsStore.next(items);
	}

	private handleCombatsUpdate(combatsMap: { [key: string]: Combat }) {
		// Get the combat with the matching id
		console.log('[handleCombatsUpdate]', combatsMap);
		this.activeCombatsStore.next(combatsMap);
	}

	private handleFinishedCombats(combats: Combat[]) {
		// Get the combat with the matching id
		console.log('[handleFinishedCombats]', combats);
		this.finishedCombatsStore.next(combats);
	}

	private handleFinishedCombatAdd(combat: Combat) {
		// Log the received combat
		console.log('[handleFinishedCombatAdd]', combat);

		// Get the current list of finished combats
		const currentCombats = this.finishedCombatsStore.getValue();

		// Add the new combat to the start of the list
		currentCombats.unshift(combat);

		// If the length of the list is greater than 100, remove the last item
		if (currentCombats.length > 100) {
			currentCombats.pop();
		}

		// Update the finishedCombatsStore with the new list
		this.finishedCombatsStore.next(currentCombats);

		// Remove the combat from the activeCombatsStore
		const combatsMap = this.activeCombatsStore.getValue();
		delete combatsMap[combat.id];
		this.activeCombatsStore.next(combatsMap);
	}

	private handleCombatUpdate(combat: Combat) {
		//console.log("[handleCombatUpdate]", combat);

		// Get the current combats map
		const combatsMap = { ...this.activeCombatsStore.getValue() }; // Spread operator to create a new object

		if (combat.closed) {
			// Remove the combat if it's closed
			delete combatsMap[combat.id];
		} else {
			// Update the combat in the map where key matches combat.id
			combatsMap[combat.id] = combat;
		}

		// Update the activeCombatsStore with the new map
		this.activeCombatsStore.next(combatsMap);
	}

	private handleMarketHistoryUpdate(items: MarketItem[]) {
		// Get the combat with the matching id
		console.log('[handleMarketHistoryUpdate]', items);
		this.marketHistoryStore.next(items);
	}

	private handleAccountUpdate(account: Account) {
		// Get the combat with the matching id
		console.log('[handleAccountUpdate]', account);
		this.selectedAccount = account;
		this.accountStore.next(account);
	}

	private handleFighterUpdate(fighter: Fighter) {
		console.log('[handleFighterUpdate]', fighter);
		if (this.selectedFighter?.id == fighter.id) {
			this.setSelectedFighter(fighter);
			this.fighterStore.next(fighter);
		}

		// Update the fighter in userFightersStore if it exists
		const currentFighters = this.userFightersStore.getValue();

		if (!currentFighters) return;
		const fighterIndex = currentFighters.findIndex((f) => f.id === fighter.id);

		if (fighterIndex !== -1) {
			// If the fighter exists in the store, update it
			currentFighters[fighterIndex] = fighter;
			this.userFightersStore.next([...currentFighters]);
		}
	}

	private handleUserFightersUpdate(fighters: Fighter[]) {
		// Get the combat with the matching id
		console.log('[handleUserFightersUpdate]', fighters);
		this.userFightersStore.next(fighters);
	}

	private handleChatUpdate(chat: Chat) {
		console.log('[handleChatUpdate]', chat);
		// Get the chat with the matching room_id
		const currentChats = this.chatBoxesStore.getValue();

		// If the chat with room_id exists, update it, otherwise add a new one
		currentChats[chat.room_id] = currentChats[chat.room_id] ? chat : { ...chat };

		// Update the BehaviorSubject with the new or updated chat
		this.chatBoxesStore.next(currentChats);

		console.log('[handleChatUpdate]', chat);
	}

	public static getInstance(): EventCloud {
		if (!EventCloud.instance) {
			EventCloud.instance = new EventCloud();
		}
		return EventCloud.instance;
	}

	public sendMessage(type: string, payload: any) {
		// Send messages via the worker
		this.worker.postMessage({ type, payload });
	}

	// Optionally, handle worker errors
	public onError(listener: (error: any) => void) {
		this.on('error', listener);
	}

	// Terminate the worker gracefully
	public terminateWorker() {
		this.worker.postMessage({ type: 'TERMINATE' });
	}

	public notify(msg: ReactNode, type: string, delay: string) {
		//console.log("[notify]", delay);
		switch (type) {
			case 'success':
				toast.success(msg, {
					position: 'bottom-right',
					autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					pauseOnFocusLoss: false,
					theme: 'dark',
				});
				break;
			case 'warn':
				toast.warn(msg, {
					position: 'bottom-right',
					autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
					pauseOnFocusLoss: false,
					theme: 'dark',
				});
				break;
			case 'error':
				toast.error(msg, {
					position: 'bottom-right',
					autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					pauseOnFocusLoss: false,
					theme: 'dark',
				});
				break;
			case 'info':
				toast.info(msg, {
					position: 'bottom-right',
					autoClose: typeof delay === 'string' ? parseInt(delay, 10) : delay, // Correct parsing
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					pauseOnFocusLoss: false,
					theme: 'dark',
				});
				break;
		}
	}

	// REQUESTS
	// case "auth_signed":
	//             type ReqData struct {
	//                 Address string `json:"address"`
	//                 Message string `json:"message"`
	//                 Timestamp int `json:"timestamp"`
	//                 V int `json:"v"`
	//                 R string`json:"r"`
	//                 S string `json:"s"`
	//             }

	public sendAuthSigned(
		address: string,
		message: string,
		timestamp: number,
		referrer: string,
		v: number,
		r: string,
		s: string
	) {
		console.log(`[sendAuthSigned] `, address, message, timestamp, v, r, s);

		this.sendMessage('SEND_AUTH_SIGNED', {
			address,
			message,
			timestamp,
			referrer,
			v,
			r,
			s,
		});
		this.track('SEND_AUTH_SIGNED', {
			address: address,
		});
	}

	public sendAuth(user_address: string | undefined, session_id: string) {
		console.log(`[sendAuth] user_address=${user_address} session_id=${session_id}`);

		this.sendMessage('SEND_AUTH', {
			uuid: this.generateUniqueUserId(),
			user_address,
			session_id,
		});
		this.track('SEND_AUTH', {
			address: user_address,
		});
	}

	public sendCreateFighter(fighter_name: string, fighter_class: number) {
		console.log(`[sendCreateFighter] name=${fighter_name} fighter_class=${fighter_class}`);

		this.sendMessage('SEND_CREATE_FIGHTER', {
			fighter_name,
			fighter_class,
		});
		this.track('SEND_CREATE_FIGHTER', {
			fighter_name: fighter_name,
		});
	}

	public sendDeleteFighter(fighter_id: string) {
		console.log(`[sendCreateFighter] id=${fighter_id}`);
		this.sendMessage('SEND_DELETE_FIGHTER', {
			fighter_id,
		});
	}

	public sendAuthFighter(fighter_id: string) {
		console.log(`[sendAuthFighter] id=${fighter_id}`);
		this.sendMessage('SEND_AUTH_FIGHTER', {
			fighter_id,
		});
	}

	public sendFight() {
		console.log(`[sendFight]`);
		this.sendMessage('SEND_FIGHT', {});
		this.track('SEND_FIGHT');
	}

	public sendDemoFight() {
		console.log(`[sendDemoFight]`);
		this.sendMessage('SEND_DEMO_FIGHT', {});
		this.track('SEND_DEMO_FIGHT');
	}

	public sendAttack(combat_id: string, attack: string, defence: string, skill: string) {
		console.log(`[sendAttack] combat_id=${combat_id} attack=${attack} defence=${defence} skill=${skill}`);
		this.sendMessage('SEND_ATTACK', {
			combat_id,
			attack,
			defence,
			skill,
		});
		this.track('SEND_ATTACK');
	}

	// public collectItem(combat_id: string, liquidate: boolean) {
	// 	console.log(`[collectItem] combat_id=${combat_id}`);
	// 	this.sendMessage("COLLECT_ITEM", {
	// 		combat_id,
	// 		liquidate,
	// 	});
	// }

	public equipItem(item_id: string) {
		console.log(`[equipItem] item_id=${item_id}`);
		this.sendMessage('EQUIP_ITEM', {
			item_id,
		});
		this.track('EQUIP_ITEM');
	}

	public unequipItem(slot: string) {
		console.log(`[unequipItem] slot=${slot}`);
		this.sendMessage('UNEQUIP_ITEM', {
			slot,
		});
		this.track('UNEQUIP_ITEM');
	}

	public buyShopItem(item_name: string) {
		console.log(`[buyShopItem] item_name=${item_name}`);
		this.sendMessage('BUY_SHOP_ITEM', {
			item_name,
		});
	}

	// public consumeItem(item_id: string) {
	// 	console.log(`[equipItem] item_id=${item_id}`);
	// 	this.sendMessage("CONSUME_ITEM", {
	// 		item_id,
	// 	});
	// }

	public upgradeItemLevel(item_id: string) {
		console.log(`[upgradeItemLevel] item_id=${item_id}`);
		this.sendMessage('UPGRADE_ITEM_LEVEL', {
			item_id,
		});
		this.track('UPGRADE_ITEM_LEVEL');
	}

	public incrementStrength() {
		console.log(`[incrementStrength]`);
		this.sendMessage('INCREMENT_STRENGTH', {});
	}

	public incrementAgility() {
		console.log(`[incrementAgility]`);
		this.sendMessage('INCREMENT_AGILITY', {});
	}

	public incrementIntelligence() {
		console.log(`[incrementIntelligence]`);
		this.sendMessage('INCREMENT_INTELLIGENCE', {});
	}

	public incrementVitality() {
		console.log(`[incrementVitality]`);
		this.sendMessage('INCREMENT_VITALITY', {});
	}

	public decrementStrength() {
		console.log(`[decrementStrength]`);
		this.sendMessage('DECREMENT_STRENGTH', {});
	}

	public decrementAgility() {
		console.log(`[decrementAgility]`);
		this.sendMessage('DECREMENT_AGILITY', {});
	}

	public decrementIntelligence() {
		console.log(`[decrementIntelligence]`);
		this.sendMessage('DECREMENT_INTELLIGENCE', {});
	}

	public decrementVitality() {
		console.log(`[decrementVitality]`);
		this.sendMessage('DECREMENT_VITALITY', {});
	}

	public sendChatMessage(room_id: string, msg: string) {
		console.log(`[sendChatMessage] `, msg);
		this.sendMessage('CHAT_MESSAGE', { room_id, msg });
	}

	// public sendCombatChatMessage(combat_id: string, msg: string) {
	// 	console.log(`[sendCombatChatMessage] `, msg);
	// 	this.sendMessage("CHAT_MESSAGE_COMBAT", { combat_id, msg });
	// }

	public sendJoin(combat_id: string) {
		console.log(`[sendJoin] `, combat_id);
		this.sendMessage('JOIN_COMBAT', { combat_id });
	}

	public sendWager(combat_id: string, wager: number) {
		console.log(`[sendWager] `, combat_id, wager);
		this.sendMessage('SEND_WAGER', { combat_id, wager });
	}

	public sendLeave(combat_id: string) {
		console.log(`[sendLeave] `, combat_id);
		this.sendMessage('LEAVE_COMBAT', { combat_id });
	}

	public listToMarket(item_id: string, price: number, price_token: number) {
		console.log(`[listToMarket] `, item_id, price, price_token);
		this.sendMessage('LIST_TO_MARKET', { item_id, price, price_token });
		this.track('LIST_TO_MARKET');
	}

	public unlistFromMarket(item_id: string) {
		console.log(`[unlistFromMarket] ${item_id}`);
		this.sendMessage('UNLIST_FROM_MARKET', { item_id });
		this.track('UNLIST_FROM_MARKET');
	}

	public openBox(item_id: string) {
		console.log(`[openBox] ${item_id}`);
		this.sendMessage('OPEN_BOX', { item_id });
		this.track('OPEN_BOX');
	}

	public buyMarketItem(market_item_id: string) {
		console.log(`[buyMarketItem] `, market_item_id);
		this.sendMessage('BUY_FROM_MARKET', { market_item_id });
		this.track('BUY_FROM_MARKET');
	}

	public liquidateItem(item_id: string) {
		console.log(`[liquidateItem] `, item_id);
		this.sendMessage('LIQUIDATE_ITEM', { item_id });
		this.track('LIQUIDATE_ITEM');
	}

	public increaseRegenLevel() {
		console.log(`[increaseRegenLevel] `);
		this.sendMessage('INCREASE_REGEN_LEVEL', {});
		this.track('INCREASE_REGEN_LEVEL');
	}

	public requestMarketHistory() {
		console.log(`[requestMarketHistory] `);
		this.sendMessage('REQUEST_MARKET_HISTORY', {});
	}

	public requestDepositAddress() {
		console.log(`[requestDepositAddress] `);
		this.sendMessage('REQUEST_DEPOSIT_ADDRESS', {});
		this.track('REQUEST_DEPOSIT_ADDRESS');
	}

	public requestFundsHistory() {
		console.log(`[requestFundsHistory] `);
		this.sendMessage('REQUEST_FUNDS_HISTORY', {});
	}

	public requestTokenDistributions() {
		console.log(`[requestTokenDistributions] `);
		this.sendMessage('REQUEST_TOKEN_DISTRIBUTIONS', {});
	}

	public requestTokenStats() {
		console.log(`[requestTokenStats] `);
		this.sendMessage('REQUEST_TOKEN_STATS', {});
	}

	public requestCombatsHistory() {
		console.log(`[requestCombatsHistory] `);
		this.sendMessage('REQUEST_COMBATS_HISTORY', {});
	}

	public buySponsoredPackage() {
		console.log(`[buySponsoredPackage] `);
		this.sendMessage('BUY_SPONSORED', {});
	}

	public requestAffiliates() {
		console.log(`[requestAffiliates] `);
		this.sendMessage('REQUEST_AFFILIATES', {});
		this.track('REQUEST_AFFILIATES');
	}

	public requestWithdraw(
		amount: number,
		address: string,
		message: string,
		timestamp: number,
		v: number,
		r: string,
		s: string
	) {
		console.log(`[requestWithdraw] `, amount, address, message, v, r, s);
		this.sendMessage('REQUEST_WITHDRAW', {
			amount,
			address,
			message,
			timestamp,
			v,
			r,
			s,
		});
	}

	public requestWithdrawToken(
		amount: number,
		address: string,
		message: string,
		timestamp: number,
		v: number,
		r: string,
		s: string
	) {
		console.log(`[requestWithdrawToken] `, amount, address, message, v, r, s);
		this.sendMessage('REQUEST_WITHDRAW_TOKEN', {
			amount,
			address,
			message,
			timestamp,
			v,
			r,
			s,
		});
	}
}

export const eventCloud = EventCloud.getInstance();
