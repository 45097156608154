// src/pages/ProfilePageNarrow.tsx

import React, { useState, useEffect } from 'react';
import { eventCloud } from '../eventCloud';
import { Fighter, Account, Wallet } from '../store/types/fighterTypes';
import { Button } from '../components/Button/Button';
import History from '../components/History/History';
import Inventory from '../components/Inventory/Inventory';
import { Shop } from '../components/Shop/Shop';
import SponsorPage from './SponsorPage';
import AffiliatePage from './AffiliatePage';
import { Market } from '../components/Market/Market';
import Modal from 'react-modal';
import FighterProfile from '../components/Fighter/FighterProfile';
import ChatBox from '../components/ChatBox/ChatBox';
import FighterStatsVertical from '../components/Fighter/FighterStatsVertical';

import { ReactComponent as IconSpinner } from '../assets/icons/svg/spinner.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/svg/close.svg';
import { ReactComponent as SponsorCrown } from '../assets/icons/svg/sponsor-crown.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/svg/history.svg';
import { ReactComponent as ShopIcon } from '../assets/icons/svg/shop.svg';
import { ReactComponent as InventoryIcon } from '../assets/icons/svg/inventory.svg';
import { ReactComponent as MarketplaceIcon } from '../assets/icons/svg/marketplace.svg';

import { ReactComponent as CheckedIcon } from '../assets/icons/svg/checked.svg';
import { ReactComponent as UncheckedIcon } from '../assets/icons/svg/unchecked.svg';
import { ReactComponent as RefreshIcon } from '../assets/icons/svg/refresh.svg';

type ProfilePageProps = {
	fighter: Fighter | null;
	account: Account | null;
	wallet: Wallet | null;
};

const ProfilePageNarrow: React.FC<ProfilePageProps> = ({ fighter, account, wallet }) => {
	const [selectedTab, setSelectedTab] = useState('');
	const [autoHit, setAutoHit] = useState(localStorage.getItem('combats.autoHit') == 'true' || false);

	const toggleAutoHit = () => {
		const newAutoHit = !autoHit;
		setAutoHit(newAutoHit);

		localStorage.setItem('combats.autoHit', newAutoHit ? 'true' : 'false');

		if (
			newAutoHit &&
			fighter &&
			fighter.health == fighter.max_health &&
			fighter.mana == fighter.max_mana &&
			!fighter.in_combat
		) {
			eventCloud.sendFight();
		}
	};

	useEffect(() => {
		if (
			autoHit == true &&
			fighter &&
			fighter.health == fighter.max_health &&
			fighter.mana == fighter.max_mana &&
			!fighter.in_combat
		) {
			setTimeout(() => {
				eventCloud.sendFight();
			}, 1000);
		}
	}, [fighter]);

	const handleFight = () => {
		eventCloud.sendFight();
	};

	const generateNewWallet = async () => {
		eventCloud.emit('generateNewWallet');
	};

	const connectMetaMask = async () => {
		eventCloud.emit('connectMetaMask');
	};

	const handleImportPrivateKey = async () => {
		eventCloud.emit('importPrivateKey');
	};

	if (!wallet) {
		return (
			<div className="fixed bottom-0 left-0 right-0 flex flex-col justify-center items-center min-h-[500px]">
				<div className="bg-gray-550 p-8 rounded-lg shadow-lg text-center max-w-lg mx-auto">
					<h1 className="text-2xl font-bold text-yellow-550 mb-4 animate-pulse">Welcome to Combats!</h1>
					<p className="text-md text-gray-200 mb-3">
						The ultimate <strong>blockchain fighting game</strong> where battles earn you{' '}
						<span className="font-bold text-yellow-550">GRIT</span> tokens!
					</p>
					<p className="text-md text-gray-200 mb-3">
						To join the fray, you&apos;ll need an{' '}
						<strong className="text-blue-550">EVM-compatible crypto wallet</strong>.
					</p>
					<p className="text-md text-gray-200 mb-5">
						We recommend{' '}
						<a
							href="https://metamask.io/"
							target="_blank"
							rel="noreferrer"
							className="text-blue-550 hover:text-blue-560 underline font-semibold"
						>
							MetaMask
						</a>{' '}
						for top-notch security. Or, jump right in by generating a wallet directly in your browser!
					</p>
					<p className="mt-6 mb-4 font-semibold text-lg text-gray-300">Choose Your Path:</p>
					<div className="space-y-2 pt-2">
						<div>
							<Button
								variant="black"
								size="large"
								onClick={() => {
									generateNewWallet();
								}}
							>
								GENERATE NEW WALLET
							</Button>
						</div>
						<div>
							<Button
								size="large"
								onClick={() => {
									connectMetaMask();
								}}
							>
								CONNECT METAMASK
							</Button>
						</div>
						<div>
							<Button
								variant="white"
								size="large"
								onClick={() => {
									handleImportPrivateKey();
								}}
							>
								IMPORT WALLET
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (!fighter) {
		return (
			<div className="flex flex-col justify-center items-center min-h-[500px] p-4">
				<div className="bg-gray-550 p-8 rounded-lg shadow-lg text-center max-w-lg mx-auto">
					<h1 className="text-2xl font-bold text-yellow-550 mb-4 animate-pulse">Time to Choose Your Champion!</h1>
					<p className="text-md text-gray-200 mb-3">
						No fighter selected yet. Every legend needs a beginning!
					</p>
					<p className="text-md text-gray-200 mb-5">
						Pick an existing warrior or forge a new one to start your journey in the arena.
					</p>
					<div className="mt-6">
						<Button variant="green" size="large" onClick={() => eventCloud.emit('create_fighter')}>
							FORGE YOUR LEGEND
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="h-full">
			<div className="flex flex-col h-full">
				<div className="order-1 xs:order-2 flex justify-center sm:w-[calc(30%)] mb-1 h-full">
					<div className="flex flex-col gap-y-2 w-full">
						<div className="flex justify-center">
							<div className="w-full">
								<Button
									onClick={handleFight}
									size="large"
									className="py-3 text-base"
									variant={fighter?.in_combat ? 'red' : 'basic'}
								>
									{fighter?.in_combat ? (
										<div className="flex justify-center gap-x-2">
											<IconSpinner width={16} />
											<div>SEARCHING OPPONENT</div>
										</div>
									) : (
										<span>FIGHT</span>
									)}
								</Button>
							</div>
						</div>
						<div
							className="flex bg-gray-550 text-xxs rounded-sm px-1 py-1 justify-between items-center gap-x-2 cursor-pointer select-none"
							onClick={toggleAutoHit}
						>
							<div className="flex gap-x-1 items-center">
								<RefreshIcon />
								Auto-Play
							</div>
							<div className="flex gap-x-1 items-center font-bold">
								{autoHit ? 'On' : 'Off'}
								{autoHit ? <CheckedIcon /> : <UncheckedIcon />}
							</div>
						</div>
						<div className="flex justify-center max-w-full max-h-[300px] sm:max-h-full gap-x-2">
							<div className="w-full overflow-x-auto flex flex-col gap-y-1">
								<FighterStatsVertical fighter={fighter} />
							</div>
							<FighterProfile
								hideHealthMana={true}
								equippable={true}
								allowItemPopover={true}
								fighter={fighter}
							/>
						</div>
						{/*<div className="flex flex-col gap-x-1 gap-y-1 w-full flex-grow min-h-[120px] h-0 overflow-y-auto mb-10">
							<div className="text-xs">News</div>
							<div className="text-xxs">
								<span className="text-blue-550">24 Dec</span>: Marketplace is now back
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">19 Dec</span>: Removed two staff bonus for wizard and
								returned weapon skill for knight
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">17 Dec</span>: Added stats decrease button. You can now
								edit the stats on the character.
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">15 Dec</span>: New bonuses for full sets (
								<a
									rel="noreferrer"
									className="text-blue-550"
									href="https://docs.combats.game/getting-started/set-bonuses"
									target="_blank"
								>
									read more
								</a>
								)
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">13 Dec</span>: New bonuses for two knight weapons based
								on weapon level (
								<a
									rel="noreferrer"
									className="text-blue-550"
									href="https://docs.combats.game/getting-started/character-classes/knight"
									target="_blank"
								>
									read more
								</a>
								)
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">13 Dec</span>: New bonuses for two wizard staffs based
								on weapon level (
								<a
									rel="noreferrer"
									className="text-blue-550"
									href="https://docs.combats.game/getting-started/character-classes/wizard"
									target="_blank"
								>
									read more
								</a>
								)
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">10 Dec</span>: Removed weapon skill for the knight class
								to balance the Knight/Wizard power.
							</div>
						</div>*/}
						{/* <div>
							<SponsorIndicator fighter={fighter} />
						</div> */}
						<div className="flex-grow min-h-[120px] h-0 mb-10">
							<ChatBox room_id="lobby0" hide_members={true} />
						</div>
						{/*<div>
							<div className="flex flex-wrap gap-x-1 gap-y-1 w-full justify-between">
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-blue-550 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-blue-550">
										<AttackIcon />
										Victories
									</div>
									<div className="text-xl md:text-2xl font-bold">{fighter?.victories}</div>
								</div>
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-red-550 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-red-550">
										<DefeatIcon />
										Defeats
									</div>
									<div className="text-xl md:text-2xl font-bold ">{fighter?.defeats}</div>
								</div>
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-yellow-500 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-yellow-500">
										<DrawIcon />
										Draws
									</div>
									<div className="text-xl md:text-2xl font-bold">{fighter?.draws}</div>
								</div>
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-gray-200 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-gray-200">
										<AbandonIcon />
										Abandons
									</div>
									<div className="text-xl md:text-2xl font-bold">{fighter?.abandons}</div>
								</div>
							</div>
						</div>*/}

						{/*<div className="flex flex-wrap gap-1">
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="purple"
									onClick={() => {
										setSelectedTab('INVENTORY');
									}}
								>
									INVENTORY
								</Button>
							</div>
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="yellow"
									onClick={() => {
										setSelectedTab('HISTORY');
									}}
								>
									HISTORY
								</Button>
							</div>
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="green"
									onClick={() => {
										setSelectedTab('SHOP');
									}}
								>
									SHOP
								</Button>
							</div>
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="gold"
									onClick={() => {
										setSelectedTab('VIP');
									}}
								>
									<div className="flex items-center justify-center text-yellow-550 gap-x-1">
										<SponsorCrown height={14} width={14} />
										VIP
									</div>
								</Button>
							</div>
							<div className="w-[calc(100%)]">
								<Button
									variant="black"
									onClick={() => {
										setSelectedTab('AFFILIATES');
									}}
								>
									<div className="flex items-center justify-center gap-x-1">REFER A FRIEND</div>
								</Button>
							</div>
						</div>*/}

						{/*<div className="text-xs text-center text-white bg-gray-550 p-1 mb-1">
							<a href="https://dmex.app" rel="noreferrer" target="_blank" className="hover:underline">
								<span className="text-purple-900 font-bold">AD:</span> Trade <b>crypto</b> futures with
								up to <span className="text-blue-550 font-bold">500x leverage</span> on{' '}
								<span className="text-yellow-500 font-bold">DMEX</span>
							</a>
						</div>*/}
					</div>
				</div>
			</div>

			<div className="fixed bottom-0 w-full -ml-2 bg-gray-550 flex p-2 text-xxs text-gray-200 justify-between items-center">
				{/*				<div className="w-1/5 flex flex-col items-center gap-y-0.5 bg-blue-550 text-black rounded-sm">
					<ProfileIcon />
					Profile
				</div>*/}
				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5 pt-1"
					onClick={() => {
						setSelectedTab('INVENTORY');
					}}
				>
					<InventoryIcon />
					Inventory
				</div>
				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5 "
					onClick={() => {
						setSelectedTab('HISTORY');
					}}
				>
					<HistoryIcon />
					History
				</div>

				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5"
					onClick={() => {
						setSelectedTab('SHOP');
					}}
				>
					<ShopIcon />
					Shop
				</div>

				<div
					className="w-1/5 flex flex-col items-center gap-y-0.5 text-yellow-550"
					onClick={() => {
						setSelectedTab('VIP');
					}}
				>
					<SponsorCrown />
					VIP
				</div>

				{/*				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5"
					onClick={() => {
						setSelectedTab('AFFILIATES');
					}}
				>
					<ReferrIcon />
					Referr
				</div>*/}

				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5"
					onClick={() => {
						setSelectedTab('MARKETPLACE');
					}}
				>
					<MarketplaceIcon />
					Marketplace
				</div>
			</div>

			<Modal
				isOpen={selectedTab === 'INVENTORY'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="INVENTORY"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>Inventory</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="h-full overflow-y-auto">
					<Inventory account={account} fighter={fighter} />
				</div>
			</Modal>
			<Modal
				isOpen={selectedTab === 'HISTORY'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="HISTORY"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>History</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto max-h-full">
					<History fighter={fighter} />
				</div>
			</Modal>
			<Modal
				isOpen={selectedTab === 'SHOP'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="SHOP"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>Shop</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto h-full">
					<Shop fighter={fighter} />
				</div>
			</Modal>

			<Modal
				isOpen={selectedTab === 'VIP'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="VIP"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>VIP</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto h-full">
					<SponsorPage fighter={fighter} />
				</div>
			</Modal>

			<Modal
				isOpen={selectedTab === 'AFFILIATES'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="AFFILIATES"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>REFER FRIENDS</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto max-h-[450px]">
					<AffiliatePage account={account} />
				</div>
			</Modal>

			<Modal
				isOpen={selectedTab === 'MARKETPLACE'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="MARKETPLACE"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>MARKETPLACE</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto h-full pb-10">
					<Market fighter={fighter} />
				</div>
			</Modal>
		</div>
	);
};

export default ProfilePageNarrow;
