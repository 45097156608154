// FighterStatsVertical.tsx

import React, { useState, useEffect } from 'react';
import { Fighter, Skill } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';

import { ReactComponent as StrengthIcon } from '../../assets/icons/svg/strength.svg';
import { ReactComponent as AgilityIcon } from '../../assets/icons/svg/agility.svg';
import { ReactComponent as IntelligenceIcon } from '../../assets/icons/svg/intelligence.svg';
import { ReactComponent as VitalityIcon } from '../../assets/icons/svg/vitality.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/svg/minus.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/svg/down-arrow.svg';

// Define the props interface
interface FighterStatsVerticalProps {
	fighter: Fighter | null;
	updateable?: boolean;
	vertical?: boolean;
}

const FighterStatsVertical: React.FC<FighterStatsVerticalProps> = ({ fighter, updateable, vertical }) => {
	const [minSkillWizDamage, setSkillMinWizDamage] = useState(0);
	const [maxSkillWizDamage, setSkillMaxWizDamage] = useState(0);
	const [damageIncrease, setDamageIncrease] = useState(0);

	useEffect(() => {
		if (!fighter || !Array.isArray(fighter.skills)) {
			return;
		}

		let minWizDamage = 0; // Start with an impossible value for comparison
		let maxWizDamage = 0; // Start with an impossible value for comparison
		let damageIncrease = 1; // Start with an impossible value for comparison

		for (const skill of fighter.skills) {
			if (skill.required_int <= fighter.intelligence) {
				if (skill.min_wiz_damage > minWizDamage) {
					minWizDamage = skill.min_wiz_damage;
					maxWizDamage = skill.max_wiz_damage;
				}

				if (skill.damage_increase > damageIncrease) {
					damageIncrease = skill.damage_increase;
				}
			}
		}

		setSkillMinWizDamage(Number(minWizDamage));
		setSkillMaxWizDamage(Number(maxWizDamage));
		setDamageIncrease(Number(damageIncrease));
	}, [fighter]);

	//console.log("fighter", fighter)
	if (!fighter) {
		return <div>No fighter selected</div>;
	}

	const handleUnequipItem = (slot: string) => {
		eventCloud.unequipItem(slot);
	};

	const handleIncrementStrength = () => {
		eventCloud.incrementStrength();
	};

	const handleIncrementAgility = () => {
		eventCloud.incrementAgility();
	};

	const handleIncrementIntelligence = () => {
		eventCloud.incrementIntelligence();
	};

	const handleIncrementVitality = () => {
		eventCloud.incrementVitality();
	};

	const handleDecrementStrength = () => {
		eventCloud.decrementStrength();
	};

	const handleDecrementAgility = () => {
		eventCloud.decrementAgility();
	};

	const handleDecrementIntelligence = () => {
		eventCloud.decrementIntelligence();
	};

	const handleDecrementVitality = () => {
		eventCloud.decrementVitality();
	};

	const getMidAttack = (
		item: Fighter,
		damageIncrease: number,
		minWizDamage: number,
		maxWizDamage: number
	): number => {
		if (item.class == 0) {
			return (item.min_damage * damageIncrease + item.max_damage * damageIncrease) / 2;
		} else {
			return (
				(((item.min_wiz_damage + minWizDamage) * (100 + item.wiz_damage)) / 100 +
					Number(((item.max_wiz_damage + maxWizDamage) * (100 + item.wiz_damage)) / 100)) /
				2
			);
		}
	};

	const target = fighter.level * 5;

	return (
		<div className="flex flex-col gap-y-1 select-none w-full text-white">
			<div>
				<div className="flex justify-between w-full text-gray-200 py-1 items-center text-xxs">
					<div>Available points</div>
					<div className="font-bold text-blue-550">{fighter.available_stats}</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<StrengthIcon width={12} height={12} />
						<div className="">Strength </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementStrength}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.strength}

						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementStrength}
								/>
							</div>
						)}
					</div>
				</div>

				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Phys Damage </div>
					<div className="font-bold flex items-center gap-x-2">
						{(fighter.min_damage * damageIncrease).toFixed(0)}-
						{(fighter.max_damage * damageIncrease).toFixed(0)}
					</div>
				</div>
				{fighter.class == 0 && (
					<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
						<div className="">Mid Damage </div>
						<div className="flex items-center gap-x-1">
							<b>
								{getMidAttack(fighter, damageIncrease, minSkillWizDamage, maxSkillWizDamage).toFixed(0)}
							</b>{' '}
							[{target}]
							<div
								className={classNames({
									'text-green-550':
										getMidAttack(fighter, damageIncrease, minSkillWizDamage, maxSkillWizDamage) -
											target >=
										0,
									'text-red-550':
										getMidAttack(fighter, damageIncrease, minSkillWizDamage, maxSkillWizDamage) -
											target <
										0,
								})}
							>
								<div className="flex gap-x-1 items-center font-bold">
									<ArrowIcon
										className={`${getMidAttack(fighter, damageIncrease, minSkillWizDamage, maxSkillWizDamage) - target >= 0 && 'rotate-180'}`}
									/>
									{(
										getMidAttack(fighter, damageIncrease, minSkillWizDamage, maxSkillWizDamage) -
										target
									).toFixed(0)}
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Critical Damage </div>
					<div className="font-bold flex items-center gap-x-2">{fighter.critical_damage_points}%</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<AgilityIcon width={12} height={12} />
						<div className="">Agility </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementAgility}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.agility}
						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementAgility}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Defence [Target]</div>
					<div className="flex items-center gap-x-1 flex">
						<div>
							<b>{fighter.defence}</b> [{target}]
						</div>
						<div
							className={classNames({
								'text-green-550': fighter.defence - target >= 0,
								'text-red-550': fighter.defence - target < 0,
							})}
						>
							<div className="flex gap-x-1 items-center font-bold">
								<ArrowIcon className={`${fighter.defence - target >= 0 && 'rotate-180'}`} />
								{fighter.defence - target}
							</div>
						</div>
					</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<IntelligenceIcon width={12} height={12} />
						<div className="">Intelligence </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementIntelligence}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.intelligence}
						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementIntelligence}
								/>
							</div>
						)}
					</div>
				</div>
				{fighter.class == 1 && (
					<>
						<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
							<div className="">Wiz Damage </div>
							<div className="font-bold flex items-center gap-x-2">
								{Number(
									((fighter.min_wiz_damage + minSkillWizDamage) * (100 + fighter.wiz_damage)) / 100
								).toFixed(0)}
								-
								{Number(
									((fighter.max_wiz_damage + maxSkillWizDamage) * (100 + fighter.wiz_damage)) / 100
								).toFixed(0)}
							</div>
						</div>
						<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
							<div className="">Mid Damage </div>
							<div className="flex items-center gap-x-1">
								<b>
									{getMidAttack(
										fighter,
										damageIncrease,
										minSkillWizDamage,
										maxSkillWizDamage
									).toFixed(0)}
								</b>{' '}
								[{target}]
								<div
									className={classNames({
										'text-green-550':
											getMidAttack(
												fighter,
												damageIncrease,
												minSkillWizDamage,
												maxSkillWizDamage
											) -
												target >=
											0,
										'text-red-550':
											getMidAttack(
												fighter,
												damageIncrease,
												minSkillWizDamage,
												maxSkillWizDamage
											) -
												target <
											0,
									})}
								>
									<div className="flex gap-x-1 items-center font-bold">
										<ArrowIcon
											className={`${getMidAttack(fighter, damageIncrease, minSkillWizDamage, maxSkillWizDamage) - target >= 0 && 'rotate-180'}`}
										/>
										{(
											getMidAttack(
												fighter,
												damageIncrease,
												minSkillWizDamage,
												maxSkillWizDamage
											) - target
										).toFixed(0)}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Max Mana </div>
					<div className="font-bold flex items-center gap-x-2">{Math.floor(fighter.max_mana)}</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<VitalityIcon width={12} height={12} />
						<div className="">Vitality </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementVitality}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.vitality}
						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementVitality}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Max HP </div>
					<div className="font-bold flex items-center gap-x-2">{Math.floor(fighter.max_health)}</div>
				</div>
			</div>
		</div>
	);
};

export default FighterStatsVertical;
