// ItemUpgradeLevel.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import { Item, Fighter, Account } from '../../store/types/fighterTypes';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { eventCloud } from '../../eventCloud';
import Sparkles from 'react-sparkle';
import Confetti from 'react-dom-confetti';

import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as UpgradeIcon } from '../../assets/icons/svg/upgrade-arrow.svg';
import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';
import { ReactComponent as TokensIcon } from '../../assets/icons/svg/tokens-yellow.svg';

type ItemUpgradeLevelProps = {
	isOpen: boolean;
	selectedItem: Item | null;
	handleUpgradeItemLevel?: (id: string) => void | null;
	onClose: any | null;
	account?: Account | null;
	fighter?: Fighter | null;

	anchor?: any;
};

export const ItemUpgradeLevel = forwardRef<HTMLDivElement, ItemUpgradeLevelProps>(
	({ isOpen, selectedItem, handleUpgradeItemLevel, account, onClose, fighter, ...rest }, ref) => {
		const [upgradingItem, setUpgradingItem] = useState<Item | null>(selectedItem);
		const [showSparkles, setShowSparkles] = useState(false);
		const [isUpgradeComplete, setIsUpgradeComplete] = useState(false);
		const [upgradeSuccessful, setUpgradeSuccessful] = useState(false);
		const [itemBurnt, setItemBurnt] = useState(false);
		const [fireConfetti, setFireConfetti] = useState(false);

		const ITEM_LEVEL_UPGRADE_PRICE = [
			0, 500, 1000, 1500, 2000, 2500, 3000, 5000, 7500, 10000, 15000, 20000, 25000, 30000, 40000, 50000,
		];

		const ITEM_LEVEL_UPGRADE_PRICE_TOKEN = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20000, 25000, 30000, 40000, 50000];

		const confettiConfig = {
			angle: 74,
			spread: 500,
			startVelocity: 50,
			elementCount: 500,
			dragFriction: 0.13,
			duration: 5000,
			stagger: 1,
			width: '9px',
			height: '12px',
			perspective: 756,
			colors: [
				'#11DEEA',
				'#497FF2',
				'#9E68D5',
				'#94A2EE',
				'#3CCC90',
				'#8B8B90',
				'#FF6058',
				'#CE6DA8',
				'#FF8058',
				'#F0A64A',
				'#F0D352',
				'#B3AA8B',
			],
		};

		useEffect(() => {
			setUpgradingItem(selectedItem);
		}, [selectedItem]);

		useEffect(() => {
			const handleItemLevelUpgraded = (data: any) => {
				console.log('handleItemLevelUpgraded', data, upgradingItem);
				if (data.item_id == upgradingItem?.id) {
					console.log('handleItemLevelUpgraded item upgraded!');
					setTimeout(() => {
						setUpgradingItem(data.upgraded_item);
						setUpgradeSuccessful(data.success);
						setItemBurnt(data.burnt);

						if (data.success) {
							setFireConfetti(true); // Trigger confetti
							setTimeout(() => {
								setFireConfetti(false); // Reset after a brief delay
							}, 200);
						}
						setShowSparkles(false);
						setIsUpgradeComplete(true);
					}, 1000);
				}
			};

			eventCloud.on('item_level_upgraded', handleItemLevelUpgraded);

			return () => {
				eventCloud.off('item_level_upgraded', handleItemLevelUpgraded);
			};
		}, [upgradingItem]);

		const handleUpgradeAttempt = (item_id: string | undefined) => {
			setShowSparkles(true); // Show the sparkle effect
			setIsUpgradeComplete(false);

			// setTimeout(() => {
			// 	setShowSparkles(false); // Hide the sparkles after animation
			// }, 10000); // Adjust timing to match animation duration

			if (handleUpgradeItemLevel && item_id) {
				handleUpgradeItemLevel(item_id);
			}
		};

		const handleCloseUpgradeModal = () => {
			setIsUpgradeComplete(false);
			setUpgradeSuccessful(false);
			onClose();
		};

		const accountHasItem = (name: string, qty: number): boolean => {
			if (!account || account.inventory == null) return false;

			const inventory = account.inventory;
			let foundQty = 0;
			for (let i = 0; i < inventory.length; i++) {
				if (inventory[i].name == name && !inventory[i].is_listed) {
					foundQty++;
				}
			}

			if (foundQty >= qty) {
				return true;
			}

			return false;
		};

		// const accountHasRequiredItems = (): boolean => {
		// 	if (!account) return false;
		// 	if (!upgradingItem) return false;

		// 	if (upgradingItem.level < 6) {
		// 		return accountHasItem('Jewel of Bless', 1);
		// 	} else {
		// 		return accountHasItem('Jewel of Soul', 1);
		// 	}

		// 	return false;
		// };

		const getLevelOnFail = (item: Item | null): number => {
			if (!item) return 0;

			if (item.level < 7) {
				return Math.max(0, item.level - 1);
			} else {
				return 0;
			}
		};

		const getUpgradePrice = (item: Item | null): number => {
			if (!item) return 0;

			return ITEM_LEVEL_UPGRADE_PRICE[item.level + 1];
		};

		const getUpgradePriceToken = (item: Item | null): number => {
			if (!item) return 0;

			return ITEM_LEVEL_UPGRADE_PRICE_TOKEN[item.level + 1];
		};

		const isSponsor = (): boolean => {
			if (fighter?.sponsor_expires && new Date(fighter.sponsor_expires) > new Date()) {
				return true;
			} else {
				return false;
			}
		};

		return (
			<>
				<Modal
					isOpen={isOpen}
					onRequestClose={handleCloseUpgradeModal}
					className="text-xs w-full max-h-full sm:w-auto sm:text-sm fixed bottom-0 sm:bottom-auto max-w-full mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 outline-none"
					overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-hidden"
				>
					<div className="relative max-h-[90vh] overflow-y-auto">
						<div className="flex justify-between items-center">
							<div>Upgrade Item</div>
							<div className="text-white cursor-pointer" onClick={handleCloseUpgradeModal}>
								<CloseIcon width={18} height={18} />
							</div>
						</div>

						<div className="flex mt-4 space-x-2 relative">
							<div className="absolute inset-0 flex justify-center items-center z-10 overflow-hidden">
								<Confetti active={fireConfetti} config={confettiConfig} />
							</div>

							{isUpgradeComplete ? (
								<div className="text-center w-full mb-10 relative min-w-[300px]">
									{upgradeSuccessful ? (
										<div className="text-2xl text-green-500">SUCCESS</div>
									) : (
										<div className="text-2xl text-red-550">FAIL</div>
									)}

									{!itemBurnt ? (
										<>
											<div className="text-xs sm:text-xl text-white my-2">
												{upgradingItem?.name} +{upgradingItem?.level}
											</div>
											<div className="flex justify-center w-full">
												<div className="max-w-[30%]">
													<ItemThumbnail item={upgradingItem} />
												</div>
											</div>
										</>
									) : (
										<div className="text-lg my-20">
											Item burnt
										</div>
									)}
								
								</div>
							) : (
								<>
									<div className="text-center w-full">
										<div className="md:text-xl">{upgradingItem?.name}</div>
										<div className="text-blue-550">{upgradingItem?.item_type}</div>

										<div className="flex justify-between">
											<div className="max-w-[100px]">
												<ItemThumbnail item={upgradingItem} />
											</div>

											<div className="relative flex-grow flex items-center justify-center px-4">
												<div className="flex justify-center flex-col items-center gap-y-2">
													<div className="text-blue-550">
														<UpgradeIcon className="w-[40px]" />
													</div>
													<div className="text-xxs sm:text-sm">Success Rate</div>
													<div className="text-blue-550 tex-xs">
														{upgradingItem && upgradingItem?.level < 6
															? upgradingItem?.has_luck
																? '100%'
																: isSponsor()
																	? '80%'
																	: '75%'
															: upgradingItem?.has_luck
																? isSponsor()
																	? '80%'
																	: '75%'
																: isSponsor()
																	? '55%'
																	: '50%'}
													</div>
												</div>
											</div>

											<div className="flex justify-center max-w-[100px]">
												{upgradingItem && (
													<ItemThumbnail
														item={{
															...upgradingItem,
															level: upgradingItem.level + 1,
															id: upgradingItem.id ?? '', // Ensure id is defined
														}}
													/>
												)}
											</div>
										</div>

										<div className="text-left text-xs text-gray-200 my-4 flex justify-between">
											<div className="flex-grow">Price</div>

											<div className="flex gap-x-2">
												<div className="text-blue-550 flex items-center font-bold">
													<CreditsIcon className="h-[11px] md:h-[12px]" />
													{formatNumLocale(getUpgradePrice(upgradingItem), 0)}
												</div>
												{getUpgradePriceToken(upgradingItem) > 0 && (
													<div className="text-yellow-550 flex items-center justify-end font-bold gap-x-1">
														<TokensIcon className="h-[12px] w-[12px] md:h-[12px]" />
														{formatNumLocale(getUpgradePriceToken(upgradingItem), 0)}
													</div>
												)}
											</div>
										</div>
										{/*<div className="flex flex-wrap gap-2 justify-center items-center">
											{upgradingItem && upgradingItem?.level < 6 ? (
												<div
													className={classNames('flex w-full items-center space-x-1', {
														'text-red-550': !accountHasItem('Jewel of Bless', 1),
														'text-green-500': accountHasItem('Jewel of Bless', 1),
													})}
												>
													<div className="flex justify-between w-full items-center text-xxs md:text-sm">
														<div className="flex-grow items-center flex  gap-x-2">
															<div className="w-[20px]">
																<ItemThumbnail
																	width={40}
																	tooltip={true}
																	item={{
																		...genEmptyItem(),
																		name: 'Jewel of Bless',
																	}}
																/>
															</div>
															<div>Jewel of Bless</div>
														</div>

														<div className="mr-2">1</div>
													</div>
												</div>
											) : (
												<div
													className={classNames('flex w-full items-center space-x-1', {
														'text-red-550': !accountHasItem('Jewel of Soul', 1),
														'text-green-500': accountHasItem('Jewel of Soul', 1),
													})}
												>
													<div className="flex justify-between w-full items-center text-xxs md:text-sm">
														<div className="flex-grow items-center flex  gap-x-2">
															<div className="w-[20px]">
																<ItemThumbnail
																	width={40}
																	tooltip={true}
																	item={{
																		...genEmptyItem(),
																		name: 'Jewel of Soul',
																	}}
																/>
															</div>
															<div>Jewel of Soul</div>
														</div>

														<div className="mr-2">1</div>
													</div>
												</div>
											)}
										</div>*/}
									</div>
									<div className="flex justify-center items-center"></div>
								</>
							)}
						</div>

						<div className="mt-4">
							{isUpgradeComplete ? (
								<Button
									size="large"
									variant="dark-gray"
									onClick={handleCloseUpgradeModal}
									className="py-2"
								>
									CLOSE
								</Button>
							) : (
								<Button
									size="large"
									onClick={() => handleUpgradeAttempt(upgradingItem?.id)}
									className="py-2"
								>
									ATTEMPT UPGRADE
								</Button>
							)}
						</div>

						{!isUpgradeComplete && upgradingItem?.parameters && fighter && (
							<div>
								<div className="text-center border-b border-gray-550 mt-2 pb-1">Requirements</div>
								<div className="text-xxs font-mono">
									<table className="w-full">
										<thead>
											<tr>
												<th className="text-left">ITEM LEVEL</th>
												{upgradingItem?.parameters[0].required_level > 0 && (
													<th className="text-right">LVL</th>
												)}
												{upgradingItem?.parameters[0].min_damage > 0 && (
													<th className="text-right">DMG</th>
												)}
												{upgradingItem?.parameters[0].defence > 0 && (
													<th className="text-right">DEF</th>
												)}
												{upgradingItem?.parameters[0].wiz_damage > 0 && (
													<th className="text-right">WIZ DMG</th>
												)}
												{upgradingItem?.parameters[0].required_strength > 0 && (
													<th className="text-right">STR</th>
												)}

												{upgradingItem?.parameters[0].required_agility > 0 && (
													<th className="text-right">AGI</th>
												)}

												{upgradingItem?.parameters[0].required_intelligence > 0 && (
													<th className="text-right">INT</th>
												)}

												<th className="text-right text-blue-550">PRICE</th>
											</tr>
										</thead>
										<tbody>
											{Object.entries(upgradingItem.parameters).map(([key, param]) => {
												return (
													<tr
														key={key}
														className={classNames(
															`${upgradingItem.level == Number(key) && 'bg-yellow-550 bg-opacity-20'}`
														)}
													>
														<td className="text-left">{key}</td>

														{param.required_level > 0 && (
															<td
																className={classNames(
																	`text-right ${param.required_level > fighter?.level && 'text-red-550'}`
																)}
															>
																{param.required_level}
															</td>
														)}

														{param.min_damage > 0 && (
															<td className={classNames(`text-right`)}>
																{param.min_damage} - {param.max_damage}
															</td>
														)}

														{param.wiz_damage > 0 && (
															<td className={classNames(`text-right`)}>
																{param.wiz_damage}
															</td>
														)}

														{param.defence > 0 && (
															<td className={classNames(`text-right`)}>
																{param.defence}
															</td>
														)}

														{param.required_strength > 0 && (
															<td
																className={classNames(
																	`text-right ${param.required_strength > fighter?.strength && 'text-red-550'}`
																)}
															>
																{param.required_strength}
															</td>
														)}

														{param.required_agility > 0 && (
															<td
																className={classNames(
																	`text-right ${param.required_agility > fighter?.agility && 'text-red-550'}`
																)}
															>
																{param.required_agility}
															</td>
														)}

														{param.required_intelligence > 0 && (
															<td
																className={classNames(
																	`text-right ${param.required_intelligence > fighter?.intelligence && 'text-red-550'}`
																)}
															>
																{param.required_intelligence}
															</td>
														)}

														<td className="text-right">
															<div className="flex gap-x-2 w-full justify-end">
																{ITEM_LEVEL_UPGRADE_PRICE_TOKEN[Number(key)] > 0 && (
																	<div className=" text-yellow-550">
																		{formatNumLocale(
																			ITEM_LEVEL_UPGRADE_PRICE_TOKEN[Number(key)],
																			0
																		)}{' '}
																		GRIT
																	</div>
																)}
																<div className=" text-blue-550 ">
																	{formatNumLocale(
																		ITEM_LEVEL_UPGRADE_PRICE[Number(key)],
																		0
																	)}{' '}
																	CR
																</div>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						)}

						{showSparkles && (
							<Sparkles
								color={[
									'#11DEEA',
									'#497FF2',
									'#9E68D5',
									'#94A2EE',
									'#3CCC90',
									'#3CCC90',
									'#8B8B90',
									'#FF6058',
									'#FF6058',
									'#CE6DA8',
									'#FF8058',
									'#F0A64A',
									'#F0D352',
									'#B3AA8B',
								]}
								count={200} // Number of sparkles
								overflowPx={20} // Overflow to cover surrounding area
								fadeOutSpeed={75} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						)}
					</div>
				</Modal>
			</>
		);
	}
);

const formatNumLocale = (price: number, decimals: number): string => {
	return new Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(price);
};

ItemUpgradeLevel.displayName = 'ItemUpgradeLevel';
