// src/pages/ProfilePageWide.tsx

import React, { useState, useEffect } from 'react';
import { eventCloud } from '../eventCloud';
import { Fighter, Account, Wallet } from '../store/types/fighterTypes';
import { Button } from '../components/Button/Button';
import History from '../components/History/History';
import Inventory from '../components/Inventory/Inventory';
import { Tabs } from '../components/Shared/Tabs';
import FighterProfile from '../components/Fighter/FighterProfile';
import FighterStatsVertical from '../components/Fighter/FighterStatsVertical';
import SponsorIndicator from '../components/Shared/SponsorIndicator';
import ChatBox from '../components/ChatBox/ChatBox';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';
import { ReactComponent as DefeatIcon } from '../assets/icons/svg/defeat.svg';
import { ReactComponent as DrawIcon } from '../assets/icons/svg/draw.svg';
import { ReactComponent as AbandonIcon } from '../assets/icons/svg/abandon.svg';
import { ReactComponent as IconSpinner } from '../assets/icons/svg/spinner.svg';

import { ReactComponent as CheckedIcon } from '../assets/icons/svg/checked.svg';
import { ReactComponent as UncheckedIcon } from '../assets/icons/svg/unchecked.svg';
import { ReactComponent as RefreshIcon } from '../assets/icons/svg/refresh.svg';

type ProfilePageProps = {
	fighter: Fighter | null;
	account: Account | null;
	wallet: Wallet | null;
};

const ProfilePageWide: React.FC<ProfilePageProps> = ({ fighter, account, wallet }) => {
	const [selectedTab, setSelectedTab] = useState('CHAT');
	const [autoHit, setAutoHit] = useState(localStorage.getItem('combats.autoHit') == 'true' || false);

	const toggleAutoHit = () => {
		const newAutoHit = !autoHit;
		setAutoHit(newAutoHit);

		localStorage.setItem('combats.autoHit', newAutoHit ? 'true' : 'false');

		if (
			newAutoHit &&
			fighter &&
			fighter.health == fighter.max_health &&
			fighter.mana == fighter.max_mana &&
			!fighter.in_combat
		) {
			eventCloud.sendFight();
		}
	};

	useEffect(() => {
		if (!autoHit) return;
		if (fighter && fighter.health == fighter.max_health && fighter.mana == fighter.max_mana && !fighter.in_combat) {
			setTimeout(() => {
				eventCloud.sendFight();
			}, 1000);
		}
	}, [fighter]);

	const handleFight = () => {
		eventCloud.sendFight();
	};

	const generateNewWallet = async () => {
		eventCloud.emit('generateNewWallet');
	};

	const connectMetaMask = async () => {
		eventCloud.emit('connectMetaMask');
	};

	const handleImportPrivateKey = async () => {
		eventCloud.emit('importPrivateKey');
	};

	return (
		<div className="h-full">
			{!wallet ? (
				<div className="flex flex-col justify-center items-center min-h-[500px] p-4 h-full">
					<div className="bg-gray-550 p-8 rounded-lg shadow-lg text-center max-w-lg mx-auto">
						<h1 className="text-4xl font-bold text-yellow-550 mb-4 animate-pulse">Welcome to Combats!</h1>
						<p className="text-lg text-gray-200 mb-3">
							The ultimate <strong>blockchain fighting game</strong> where battles earn you{' '}
							<span className="font-bold text-yellow-550">GRIT</span> tokens!
						</p>
						<p className="text-lg text-gray-200 mb-3">
							To join the fray, you&apos;ll need an <strong className="text-blue-550">EVM-compatible crypto wallet</strong>.
						</p>
						<p className="text-lg text-gray-200 mb-5">
							We recommend{' '}
							<a
								href="https://metamask.io/"
								target="_blank"
								rel="noreferrer"
								className="text-blue-550 hover:text-blue-560 underline font-semibold"
							>
								MetaMask
							</a>{' '}
							for top-notch security. Or, jump right in by generating a wallet directly in your browser!
						</p>
						<p className="mt-6 mb-4 font-semibold text-xl text-gray-300">Choose Your Path:</p>
						<div className="space-y-2 pt-2">
							<div>
								<Button
									variant="black"
									size="large"
									onClick={() => {
										generateNewWallet();
									}}
								>
									GENERATE NEW WALLET
								</Button>
							</div>
							<div>
								<Button
									size="large"
									onClick={() => {
										connectMetaMask();
									}}
								>
									CONNECT METAMASK
								</Button>
							</div>
							<div>
								<Button
									variant="white"
									size="large"
									onClick={() => {
										handleImportPrivateKey();
									}}
								>
									IMPORT WALLET
								</Button>
							</div>
						</div>
					</div>
				</div>
			) : !fighter ? (
				<div className="flex flex-col justify-center items-center min-h-[500px] p-4">
					<div className="bg-gray-550 p-8 rounded-lg shadow-lg text-center max-w-lg mx-auto">
						<h1 className="text-2xl font-bold text-yellow-550 mb-4 animate-pulse">Time to Choose Your Champion!</h1>
						<p className="text-md text-gray-200 mb-3">
							No fighter selected yet. Every legend needs a beginning!
						</p>
						<p className="text-md text-gray-200 mb-5">
							Pick an existing warrior or forge a new one to start your journey in the arena.
						</p>
						<div className="mt-6">
							<Button variant="green" size="large" onClick={() => eventCloud.emit('create_fighter')}>
								FORGE YOUR LEGEND
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col xs:flex-row xs:space-x-4 h-[calc(100%-4rem)]">
					<div className="flex-grow order-2 xs:order-1 w-full h-full">
						{/*<div className="text-xs text-center text-white bg-gray-550 p-1 mb-1">
						<a href="https://dmex.app" rel="noreferrer" target="_blank" className="hover:underline">
							<span className="text-purple-900 font-bold">AD:</span> Trade <b>crypto</b> futures with up
							to <span className="text-blue-550 font-bold">500x leverage</span> and no KYC on{' '}
							<span className="text-yellow-500 font-bold">DMEX</span>
						</a>
					</div>*/}
						<div className="xs:mt-0">
							<Tabs
								variant="block"
								items={['CHAT', 'INVENTORY', 'STATS', 'HISTORY']}
								noLine={true}
								onSelect={(tab: string) => {
									setSelectedTab(tab);
								}}
							/>
						</div>

						<div className="xxs:overflow-y-auto h-full md:pt-4">
							{selectedTab === 'CHAT' && (
								<div className="h-full flex flex-col">
									<div className="flex-grow min-h-[120px] h-0">
										<ChatBox room_id="lobby0" hide_members={true} />
									</div>
								</div>
							)}
							{selectedTab === 'STATS' && (
								<div className="w-full overflow-x-auto flex flex-col gap-y-1 text-sm mt-4">
									<FighterStatsVertical fighter={fighter} />
								</div>
							)}

							{selectedTab == 'HISTORY' && (
								<div className="flex flex-col space-y-4 h-full">
									<div className="flex flex-wrap gap-x-1 gap-y-1 w-full justify-between">
										{/* Stat Boxes */}
										<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-blue-550 border rounded-sm p-2">
											<div className="flex gap-x-1 text-xs md:text-m text-blue-550">
												<AttackIcon />
												Victories
											</div>
											<div className="text-xl md:text-2xl font-bold">{fighter?.victories}</div>
										</div>
										<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-red-550 border rounded-sm p-2">
											<div className="flex gap-x-1 text-xxs md:text-m text-red-550">
												<DefeatIcon />
												Defeats
											</div>
											<div className="text-xl md:text-2xl font-bold ">{fighter?.defeats}</div>
										</div>
										<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-yellow-500 border rounded-sm p-2">
											<div className="flex gap-x-1 text-xxs md:text-m text-yellow-500">
												<DrawIcon />
												Draws
											</div>
											<div className="text-xl md:text-2xl font-bold">{fighter?.draws}</div>
										</div>
										<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-gray-200 border rounded-sm p-2">
											<div className="flex gap-x-1 text-xxs md:text-m text-gray-200">
												<AbandonIcon />
												Abandons
											</div>
											<div className="text-xl md:text-2xl font-bold">{fighter?.abandons}</div>
										</div>
									</div>
									<div className="flex-grow md:overflow-y-auto">
										<History fighter={fighter} />
									</div>
								</div>
							)}

							{selectedTab === 'INVENTORY' && (
								<div className="h-full">
									<Inventory account={account} fighter={fighter} />
								</div>
							)}
						</div>
					</div>

					<div className="order-1 xs:order-2 flex justify-center sm:w-[calc(30%)] mb-1">
						<div className="flex flex-col gap-y-2 w-full">
							<div className="flex flex-col justify-center gap-y-1">
								<div className="w-full">
									<Button
										onClick={handleFight}
										size="large"
										className="py-3 text-base"
										variant={fighter?.in_combat ? 'red' : 'basic'}
									>
										{fighter?.in_combat ? (
											<div className="flex justify-center gap-x-2">
												<IconSpinner width={16} />
												<div>SEARCHING OPPONENT</div>
											</div>
										) : (
											<span>FIGHT</span>
										)}
									</Button>
								</div>
								<div
									className="flex bg-gray-550 rounded-sm px-1 py-1 justify-between items-center gap-x-2 cursor-pointer select-none"
									onClick={toggleAutoHit}
								>
									<div className="flex gap-x-1 items-center">
										<RefreshIcon />
										Auto-Play
									</div>
									<div className="flex gap-x-1 items-center font-bold">
										{autoHit ? 'On' : 'Off'}
										{autoHit ? <CheckedIcon /> : <UncheckedIcon />}
									</div>
								</div>
							</div>
							<div className="flex justify-center max-w-full max-h-[calc(60vh)] sm:max-h-full">
								<FighterProfile
									hideHealthMana={true}
									equippable={true}
									allowItemPopover={true}
									fighter={fighter}
								/>
							</div>
							<div>
								<SponsorIndicator fighter={fighter} />
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProfilePageWide;
