// ItemUpgradeLevel.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import { Item, Fighter, Account } from '../../store/types/fighterTypes';
import Confetti from 'react-dom-confetti';

import Modal from 'react-modal';
import Spinwheel from './Spinwheel';


type OpenBoxProps = {
    isOpen: boolean;
    droppedItem: Item | null;
    selectedItem: Item | null;
    itemSelection: Item[] | null;
    onClose: any | null;
    account?: Account | null;
    fighter?: Fighter | null;

    anchor?: any;
};

export const OpenBox = forwardRef<HTMLDivElement, OpenBoxProps>(
    ({ isOpen, selectedItem, droppedItem, itemSelection, account, onClose, fighter, ...rest }, ref) => {
        const [fireConfetti, setFireConfetti] = useState(false);

        const confettiConfig = {
            angle: 74,
            spread: 500,
            startVelocity: 50,
            elementCount: 500,
            dragFriction: 0.13,
            duration: 5000,
            stagger: 1,
            width: '9px',
            height: '12px',
            perspective: 756,
            colors: [
                '#11DEEA',
                '#497FF2',
                '#9E68D5',
                '#94A2EE',
                '#3CCC90',
                '#8B8B90',
                '#FF6058',
                '#CE6DA8',
                '#FF8058',
                '#F0A64A',
                '#F0D352',
                '#B3AA8B',
            ],
        };

        useEffect(() => {
            if (fireConfetti) {
                setTimeout(() => {
                    setFireConfetti(false);
                }, 500);
            }
        }, [fireConfetti]);

        if (!isOpen) return null;

        return (
            <div className="absolute z-10 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-95 flex justify-center items-center">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Confetti active={fireConfetti} config={confettiConfig} />
                </div>
                <div className="w-[600px] max-w-full bg-black border border-gray-550 py-10">
                    
                    <Spinwheel 
                        spinwheel_items={itemSelection} 
                        dropped_item={droppedItem} 
                        onClose={onClose} 
                        setFireConfetti={setFireConfetti}
                    />
                </div>
            </div>
        );
    }
);

const formatNumLocale = (price: number, decimals: number): string => {
    return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(price);
};

OpenBox.displayName = 'OpenBox';
